import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { message, Card } from 'antd';
import { debounce } from 'lodash';
import { getSkipCount } from 'utils';
import { AddButton, AddIcon } from 'modules/requests/requests.styles';
import { TableView } from '../../../components/StyledLayout/Layout.styled';
import SearchHeader from '../../../components/SearchHeader';
import { getSortType, categoryTableColumn } from './listing.helper';
import {
  deleteAdditionalCategory,
  getAdditionalCategories,
} from 'redux/actions/additionalCategoryActions';
import {
  selectAllAdditionalCategories,
  selectAditionalCategoryCount,
  selectAdditionalCategoryLoading,
  setFilters,
} from 'redux/reducers/additionalCategoryReducer';
const AdditionalCategoryListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allAdditionalCategories = useSelector(selectAllAdditionalCategories);
  const isLoading = useSelector(selectAdditionalCategoryLoading);
  const allAdditionalCategoriesListCount = useSelector(
    selectAditionalCategoryCount
  );

  const initialPageConfig = {
    current: 1,
    pageSize: 10,
    limit: 10,
    skip: getSkipCount(1, 10),
    role: 'admin',
  };
  const [pageConfig, setPageConfig] = useState(initialPageConfig);

  const onChange = (pagination, filters, sorter) => {
    const newPageConfig = {
      current: pagination.current,
      pageSize: pagination.pageSize,
      limit: pagination.pageSize,
      skip: getSkipCount(pagination.current, pagination.pageSize),
      sortBy: getSortType(sorter),
      status: filters?.status ? filters.status[0] : '',
      userType: filters?.userType ? filters.userType[0] : '',
      searchKey: pageConfig?.searchKey,
      role: 'admin',
    };
    setPageConfig(newPageConfig);

    dispatch(setFilters(newPageConfig));
  };

  const onCategoryDelete = async (category) => {
    try {
      const body = {
        categoryId: category._id,
      };
      await dispatch(deleteAdditionalCategory(body));
      message.success('Category deleted successfully');
    } catch (err) {
      message.error('Error while deleting category');
    }
  };

  useEffect(() => {
    dispatch(getAdditionalCategories(pageConfig));
  }, [dispatch, pageConfig]);

  const categoryColumns = categoryTableColumn(onCategoryDelete);
  const handleSearch = debounce((e) => {
    setPageConfig({
      ...pageConfig,
      searchKey: e.target.value,
    });
  }, 500);
  return (
    <>
      <SearchHeader handleSearch={handleSearch} />
      <Card
        title="Add Ons"
        bordered={true}
        extra={
          <>
            <AddButton
              onClick={() => navigate('/dashboard/addAdditionalCategory')}
            >
              <AddIcon style={{ marginRight: '4px' }} />
              New Add Ons
            </AddButton>
          </>
        }
      >
        <TableView
          columns={categoryColumns}
          dataSource={allAdditionalCategories}
          pagination={{
            position: ['none', 'bottomRight'],
            total: allAdditionalCategoriesListCount,
            showSizeChanger: false,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} Categories`,
            showQuickJumper: true,
            defaultPageSize: 50,
            ...pageConfig,
          }}
          scroll={{ x: 1000 }}
          onChange={onChange}
          loading={isLoading}
          bordered={true}
        />
      </Card>
    </>
  );
};

AdditionalCategoryListing.propTypes = {};

export default AdditionalCategoryListing;

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import authReducer from './authReducer';
import categoryReducer from './categoryReducer';
import productReducer from './productReducer';
import notificationReducer from './notificationReducer';
import orderReducer from './orderReducer';
import contactDataReducer from './contactDataReducer';
import additionalCategoryReducer from './additionalCategoryReducer';
import usersReducer from './usersReducer';
import eventDiscountsReducer from './eventDiscountsReducer';
import LogsReducer from './logReducer';
import StoreLocationReducer from './storeLocationReducer';
import bannerReducer from './bannerReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  category: categoryReducer,
  product: productReducer,
  notification: notificationReducer,
  order: orderReducer,
  contactData: contactDataReducer,
  additionalCategory: additionalCategoryReducer,
  users: usersReducer,
  eventDiscounts: eventDiscountsReducer,
  log: LogsReducer,
  storeLocations: StoreLocationReducer,
  banner: bannerReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export default persistedReducer;

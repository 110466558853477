import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddButton, AddIcon } from 'modules/requests/requests.styles';
import { Colors } from 'constant';
import { message, Card } from 'antd';
import { storeLocationTableColumn } from './listing.helper';
import { TableView } from '../../../components/StyledLayout/Layout.styled';
import SearchHeader from '../../../components/SearchHeader';
import {
  deleteStoreLocation,
  getstoreLocation,
} from 'redux/actions/storeLocationActions';
import {
  selectAllStoreLocations,
  selectStoreLocationLoading,
} from 'redux/reducers/storeLocationReducer';

const Listing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allStoreLocation = useSelector(selectAllStoreLocations);
  const isLoading = useSelector(selectStoreLocationLoading);

  useEffect(() => {
    dispatch(getstoreLocation());
  }, [dispatch]);

  const onStoreLocationDelete = async (storeLocation) => {
    try {
      const body = {
        id: storeLocation._id,
      };
      await dispatch(deleteStoreLocation(body));
      message.success('Store Location deleted successfully');
    } catch (err) {
      message.error('Error while deleting store location');
    }
  };

  const storeLocationColumns = storeLocationTableColumn(onStoreLocationDelete);

  return (
    <>
      <SearchHeader isSearch={false} />
      <Card
        title="Store Locations"
        bordered={true}
        extra={
          <>
            <AddButton
              color={Colors.YELLOW}
              onClick={() => navigate('/dashboard/addStoreLocation')}
              className="space-align-block"
            >
              <AddIcon />
              New
            </AddButton>
          </>
        }
      >
        <TableView
          columns={storeLocationColumns}
          dataSource={allStoreLocation}
          scroll={{ x: 1000 }}
          loading={isLoading}
          bordered={true}
        />
      </Card>
    </>
  );
};
Listing.propTypes = {};

export default Listing;

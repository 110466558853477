import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddButton, AddIcon } from 'modules/requests/requests.styles';
import { Colors } from 'constant';
import { getSkipCount } from 'utils';
import { message, Card } from 'antd';
import { eventDiscountTableColumn } from './listing.helper';
import { TableView } from '../../../components/StyledLayout/Layout.styled';

import SearchHeader from '../../../components/SearchHeader';
import {
  deleteEventDiscount,
  getEventDiscounts,
} from 'redux/actions/eventDiscountActions';
import {
  selectAllEventDiscounts,
  selectEventDiscountCount,
  selectEventDiscountLoading,
} from 'redux/reducers/eventDiscountsReducer';

const Listing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allEventDiscounts = useSelector(selectAllEventDiscounts);
  const isLoading = useSelector(selectEventDiscountLoading);
  const allEventDiscountsListCount = useSelector(selectEventDiscountCount);

  const initialPageConfig = {
    current: 1,
    pageSize: 10,
    limit: 10,
    skip: getSkipCount(1, 10),
  };
  const [pageConfig, setPageConfig] = useState(initialPageConfig);
  const onChange = (pagination, filters) => {
    const newPageConfig = {
      current: pagination.current,
      pageSize: pagination.pageSize,
      limit: pagination.pageSize,
      skip: getSkipCount(pagination.current, pagination.pageSize),
      status: filters?.status ? filters.status[0] : '',
      userType: filters?.userType ? filters.userType[0] : '',
      searchKey: pageConfig?.searchKey,
    };
    setPageConfig(newPageConfig);
  };

  useEffect(() => {
    dispatch(getEventDiscounts(pageConfig));
  }, [dispatch, pageConfig]);

  const onEventDiscountDelete = async (eventDiscount) => {
    try {
      const body = {
        id: eventDiscount._id,
      };
      await dispatch(deleteEventDiscount(body));
      message.success('Event discount deleted successfully');
    } catch (err) {
      message.error('Error while deleting Event discount');
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const eventDiscountColumns = eventDiscountTableColumn(onEventDiscountDelete);

  return (
    <>
      <SearchHeader isSearch={false} />
      <Card
        title="EventDiscount"
        bordered={true}
        extra={
          <>
            <AddButton
              color={Colors.YELLOW}
              onClick={() => navigate('/dashboard/addEventsDiscount')}
              className="space-align-block"
            >
              <AddIcon />
              New
            </AddButton>
          </>
        }
      >
        <TableView
          columns={eventDiscountColumns}
          dataSource={allEventDiscounts}
          pagination={{
            position: ['none', 'bottomRight'],
            total: allEventDiscountsListCount,
            showSizeChanger: false,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} Event Discounts`,
            showQuickJumper: true,
            defaultPageSize: 50,
            ...pageConfig,
          }}
          scroll={{ x: 1000 }}
          onChange={onChange}
          loading={isLoading}
          bordered={true}
        />
      </Card>
    </>
  );
};
Listing.propTypes = {};

export default Listing;

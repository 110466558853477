import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import {
  UserOutlined,
  ShoppingCartOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons';
import {
  getSalesSummary,
  getSalesHistory,
} from 'redux/actions/dashboardActions';
import {
  StyledWrapper,
  TableView,
} from '../../components/StyledLayout/Layout.styled';
import { HistoryTableColumn } from './detail.helper';
import CardColumn from './CardColumn';

const Dashboard = () => {
  const dispatch = useDispatch();
  const historyColumns = HistoryTableColumn();
  const [salesSummary, setSalesSummary] = useState({});
  const [salesHistory, setSalesHistory] = useState([]);

  useEffect(() => {
    dispatch(getSalesSummary()).then((res) => {
      setSalesSummary(res?.payload);
    });
    dispatch(getSalesHistory()).then((res) => {
      setSalesHistory(res?.payload?.history);
    });
  }, [dispatch]);

  return (
    <>
      <StyledWrapper>
        <Row gutter={16} style={{ marginTop: '2rem' }}>
          <Col span={8} style={{ textAlign: 'center' }}>
            <CardColumn
              title="Number of customers"
              number={salesSummary?.userCount || 0}
              icon={<UserOutlined style={{ fontSize: '2.5rem' }} />}
            />
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <CardColumn
              title="Number of orders"
              number={salesSummary?.orderCount || 0}
              icon={<ShoppingCartOutlined style={{ fontSize: '2.5rem' }} />}
            />
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <CardColumn
              title="Amount of sales"
              number={`$${salesSummary?.saleSum || 0}`}
              icon={<DollarCircleOutlined style={{ fontSize: '2.5rem' }} />}
            />
          </Col>
        </Row>
      </StyledWrapper>
      <StyledWrapper style={{ marginTop: '3rem' }}>
        <Card title="Summary of Sales and Customers">
          <TableView
            columns={historyColumns}
            dataSource={salesHistory}
            bordered={false}
            pagination={false}
          />
        </Card>
      </StyledWrapper>
    </>
  );
};

Dashboard.propTypes = {
  children: PropTypes.node,
};

export default Dashboard;

export const userAPIs = {
  get: {
    url: 'allUsers/customers',
    method: 'get',
    isAuthEnabled: true,
  },
  getDetail: {
    url: 'allUsers/customers',
    method: 'get',
    isAuthEnabled: true,
  },
};

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Typography,
  message,
  Space,
  Radio,
  Checkbox,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  addAdditionalCategory,
  updateAdditionalCategory,
  deleteAddition,
} from 'redux/actions/additionalCategoryActions';
import { StyledLayout } from '../../../components/StyledLayout/Layout.styled';
import { itemsFields } from './newCategory.helper';
import GoBack from '../../../components/GoBack';

const { Title } = Typography;

const AddCategoryModal = () => {
  const [loading, setLoading] = useState(false);
  const [allItems, setAllItems] = useState(true);
  const location = useLocation();
  const { category } = location?.state || {};

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleFinish = async (values) => {
    try {
      setLoading(true);
      values.maxItems = allItems ? 'all' : parseInt(values.maxItems);
      if (category?._id) {
        await dispatch(
          updateAdditionalCategory({ data: values, id: category?._id })
        ).unwrap();
      } else {
        await dispatch(addAdditionalCategory(values)).unwrap();
      }

      form.resetFields();
      setLoading(false);
      navigate('/dashboard/additionalCategory');
    } catch (e) {
      setLoading(false);
      message.error('Error while adding additional category');
    }
  };
  const deleteAdditions = async (addition) => {
    try {
      const additions = category?.additions[addition?.key];
      if (additions?._id) {
        const body = {
          categoryId: additions._id,
        };
        await dispatch(deleteAddition(body));
        message.success('Category Addition deleted successfully');
      }
    } catch (err) {
      message.error('Error while deleting category addition');
    }
  };
  useEffect(() => {
    if (!(category?.maxItems === 'all')) {
      setAllItems(false);
    }
  }, [category]);
  return (
    <>
      <GoBack linkUrl="/dashboard/additionalCategory" />
      <StyledLayout>
        <Title level={4}>Create new Add Ons</Title>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          initialValues={{
            ...category,
            englishName: category?.name?.en,
            arabicName: category?.name?.ar,
            items: category?.additions?.map((itemObj) => ({
              'name.en': itemObj?.name?.en,
              'name.ar': itemObj?.name?.ar,
              price: itemObj?.price,
              _id: itemObj?._id,
              status: itemObj?.status,
            })),
            checked: category?.maxItems === 'all',
            maxItems: category?.maxItems,
          }}
        >
          <Form.Item
            label="Name"
            name="englishName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
            label="Name in arabic"
            name="arabicName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item> */}

          {itemsFields(Form, deleteAdditions)}
          <Form.Item
            label="Max Items"
            name="maxItems"
            rules={[{ required: false }]}
          >
            <Input type="number" disabled={allItems} required min={1} />
          </Form.Item>
          <Form.Item name="checked" valuePropName="checked">
            <Checkbox
              onChange={(e) => {
                setAllItems(!allItems);
              }}
            >
              All
            </Checkbox>
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Radio.Group defaultValue={category?.status || 'Enabled'}>
              <Space direction="vertical">
                <Radio value={'Enabled'}>Enabled</Radio>
                <Radio value={'Disabled'}>Disabled</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="button"
              loading={loading}
            >
              Submit
            </Button>
            <Button
              type="secondary"
              htmlType="button"
              className="cancelButton"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </StyledLayout>
    </>
  );
};

export default AddCategoryModal;

import { Table } from 'antd';
import { Colors } from 'constant';
import styled from 'styled-components';
import { DeleteOutlined, ReadOutlined } from '@ant-design/icons';

export const OrdersTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: ${Colors.WHITE};
    border-bottom: 1px solid ${Colors.BORDER_GREY};
  }
`;

export const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${Colors.LIGHT_BLACK};
`;
export const StyledReadOutlined = styled(ReadOutlined)`
  color: ${Colors.LIGHT_BLACK};
`;

export const StyledPendingStatus = styled.span`
  color: ${Colors.YELLOW};
  font-weight: 600;
`;

export const StyledPreparingStatus = styled.span`
  color: ${Colors.YELLOW};
  font-weight: 600;
`;

export const StyledCollectedStatus = styled.span`
  color: ${Colors.GREEN};
  font-weight: 600;
`;

export const StyledRejectedStatus = styled.span`
  color: ${Colors.RED};
  font-weight: 600;
`;

export const StyledCancelledStatus = styled.span`
  color: ${Colors.GREY};
  font-weight: 600;
`;

export const StyledExportButton = styled.button`
  padding: 2px 25px;
  cursor: pointer;
  border: 1px solid ${Colors.GREY};
  background-color: transparent;
  color: ${Colors.BLACK};
  font-size: 1.3em;
  box-shadow: 0 6px 6px rgb(0 0 0 / 1%);
  :hover {
    background-color: #f8f8f8;
    box-shadow: 0px 10px 20px rgb(153 158 156 / 20%);
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button as AntdButton } from 'antd';
import Text from 'components/Text';
import { Colors } from 'constant';

// Props of button should be same as in antd docs
const Button = ({ mode, color, fullWidth, children, ...props }) =>
  mode === 'custom' ? (
    <StyledButton type={color || 'primary'} fullWidth={fullWidth} {...props}>
      {children}
    </StyledButton>
  ) : (
    <StyledButton type={color || 'primary'} fullWidth={fullWidth} {...props}>
      <Text size="14px" color={Colors.LIGHT_BLACK} type="MEDIUM">
        {children}
      </Text>
    </StyledButton>
  );

const buttonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20rem;
  padding: 7px 20px;
  font-size: 14px;
  height: auto;
  font-weight: 700;
  min-height: unset;
`;
const StyledButton = styled(AntdButton)`
  ${buttonStyles}
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  ${(props) =>
    props.type !== 'primary' &&
    css`
      background: ${props.type};
      border-color: ${props.type};
      span {
        color: ${Colors.LIGHT_BLACK};
      }
      &:hover,
      &:focus {
        background: ${props.type};
        opacity: 0.8;
      }
    `}
`;
Button.propTypes = {
  color: PropTypes.string,
  mode: PropTypes.string,
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
};

export default Button;

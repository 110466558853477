import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminAPIs } from 'services/api';
import axiosInstance from 'services/apiService';

export const getAllAdmins = createAsyncThunk(
  'admin/getAllAdmins',
  async (payload) => {
    const { data } = await axiosInstance({
      ...adminAPIs.get,
      params: payload,
    });
    return data;
  }
);

export const addAdmin = createAsyncThunk('admin/addAdmin', async (payload) => {
  const { data } = await axiosInstance({
    ...adminAPIs.add,
    data: payload,
  });
  return data;
});

export const updateAdminStatus = createAsyncThunk(
  'admin/status',
  async (payload) => {
    const { data } = await axiosInstance({
      ...adminAPIs.updateStatus,
      data: payload,
    });
    return data;
  }
);

export const deleteAdmin = createAsyncThunk(
  'admin/deleteAdmin',
  async (payload) => {
    const { data } = await axiosInstance({
      ...adminAPIs.delete,
      url: `admin/user/${payload._id}`,
    });
    return data;
  }
);

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSkipCount } from 'utils';
import { debounce } from 'lodash';
import { Card } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { getUsers } from 'redux/actions/usersActions';
import { usersTableColumn, exportExcelFile } from './listing.helper';
import {
  TableView,
  StyledExportButton,
} from '../../../components/StyledLayout/Layout.styled';
import SearchHeader from '../../../components/SearchHeader';
import {
  selectAllUsers,
  selectUsersCount,
  selectUsersLoading,
} from 'redux/reducers/usersReducer';

const Listing = () => {
  const dispatch = useDispatch();

  const allUsers = useSelector(selectAllUsers);
  const isLoading = useSelector(selectUsersLoading);
  const allUsersListCount = useSelector(selectUsersCount);

  const initialPageConfig = {
    current: 1,
    pageSize: 10,
    limit: 10,
    skip: getSkipCount(1, 10),
  };
  const [pageConfig, setPageConfig] = useState(initialPageConfig);
  const onChange = (pagination, filters) => {
    const newPageConfig = {
      current: pagination.current,
      pageSize: pagination.pageSize,
      limit: pagination.pageSize,
      skip: getSkipCount(pagination.current, pagination.pageSize),
      status: filters?.status ? filters.status[0] : '',
      userType: filters?.userType ? filters.userType[0] : '',
      searchKey: pageConfig?.searchKey,
    };
    setPageConfig(newPageConfig);
  };

  useEffect(() => {
    dispatch(getUsers(pageConfig));
  }, [dispatch, pageConfig]);

  const usersColumns = usersTableColumn();
  const handleSearch = debounce((e) => {
    setPageConfig({
      ...pageConfig,
      searchKey: e.target.value,
    });
  }, 500);
  const tableRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SearchHeader handleSearch={handleSearch} />
      <Card
        title="Customers"
        bordered={true}
        extra={
          <>
            <StyledExportButton
              onClick={() => {
                exportExcelFile(allUsers, 'clients');
              }}
            >
              <DownloadOutlined /> Export{' '}
            </StyledExportButton>
          </>
        }
      >
        <TableView
          ref={tableRef}
          columns={usersColumns}
          dataSource={allUsers}
          pagination={{
            position: ['none', 'bottomRight'],
            total: allUsersListCount,
            showSizeChanger: false,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} Customers`,
            showQuickJumper: true,
            defaultPageSize: 50,
            ...pageConfig,
          }}
          scroll={{ x: 1000 }}
          onChange={onChange}
          loading={isLoading}
          bordered={true}
        />
      </Card>
    </>
  );
};
Listing.propTypes = {};

export default Listing;

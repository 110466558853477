import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'underscore';
import { getCategories, deleteCategory } from 'redux/actions/categoryActions';

const categoryAdapter = createEntityAdapter({
  selectId: (category) => category._id,
});
const categoryReducer = createSlice({
  name: 'category',
  initialState: categoryAdapter.getInitialState({
    loading: true,
    error: null,
    allCategoriesListCount: 0,
    filters: {
      limit: 50,
      sortBy: '',
      searchKey: '',
    },
  }),
  reducers: {
    setFilters: (state, action) => {
      const filterUpdates = action.payload;
      Object.keys(filterUpdates).forEach((filterKey) => {
        if (!_.isEqual(state.filters[filterKey], action.payload[filterKey])) {
          if (filterKey !== 'skip' && filterKey !== 'current') {
            filterUpdates.current = 1;
            filterUpdates.skip = 0;
          }
          state.filters[filterKey] = action.payload[filterKey];
        }
      });
    },
  },
  extraReducers: {
    [getCategories.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.allCategoriesListCount = action.payload.count;
      categoryAdapter.upsertMany(state, action.payload.categories);
      state.ids = action.payload.categories.map((category) => category._id);
    },
    [getCategories.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [deleteCategory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteCategory.fulfilled]: (state, action) => {
      state.loading = false;
      categoryAdapter.removeOne(state, action.meta.arg.categoryId);
      state.allCategoriesListCount = action.payload.count - 1;
    },
    [deleteCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});
export const selectCategoryLoading = (state) => state.category.loading;
export const selectCategoryCount = createSelector(
  [(state) => state.category.allCategoriesListCount],
  (count) => count
);
export const {
  selectAll: selectAllCategories,
  selectById: selectCategoryById,
} = categoryAdapter.getSelectors((state) => state.category);
export const { setFilters } = categoryReducer.actions;
export default categoryReducer.reducer;

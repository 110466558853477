import { createAsyncThunk } from '@reduxjs/toolkit';
import { eventDiscountsAPIs } from 'services/api';
import axiosInstance from 'services/apiService';

export const addEventDiscounts = createAsyncThunk(
  'eventDiscounts/add',
  async (payload) => {
    const { data } = await axiosInstance({
      ...eventDiscountsAPIs.add,
      data: payload,
    });
    return data;
  }
);

export const deleteEventDiscount = createAsyncThunk(
  'eventDiscounts/delete',
  async ({ id }) => {
    const api = eventDiscountsAPIs.delete;
    const { data } = await axiosInstance({
      ...api,
      url: `eventDiscounts/${id}/`,
    });
    return data.message;
  }
);

export const getEventDiscounts = createAsyncThunk(
  'eventDiscounts/get',
  async (payload) => {
    const { data } = await axiosInstance({
      ...eventDiscountsAPIs.get,
      params: payload,
    });
    return data;
  }
);

export const updateEventDiscounts = createAsyncThunk(
  'eventDiscounts/update',
  async (payload) => {
    const api = eventDiscountsAPIs.update;

    const { data } = await axiosInstance({
      ...api,
      url: `EventDiscounts/${payload._id}/`,
      data: payload.values,
    });
    return data;
  }
);

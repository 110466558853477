import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addBanner, updateBanner } from 'redux/actions/bannerActions';
import GoBack from '../../../components/GoBack';
import { StyledLayout } from '../../../components/StyledLayout/Layout.styled';
import UploadImage from '../../../components/Upload/Upload';

import TextField from '@mui/material/TextField';
import { Box } from '@mui/material/';

import { Typography, Button, message } from 'antd';
const { Title } = Typography;

function NewBanner() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { banner } = location?.state || {};

  const [fileObject, setFileObject] = useState({});
  const [title, setTitle] = useState(`${banner?._id ? banner.title : ''}`);
  const [loading, setLoading] = useState(false);

  const handleFinish = async (e) => {
    e.preventDefault();
    if (Object.keys(fileObject).length === 0 && !banner?._id) {
      message.error('banner image is required');
      return;
    }
    try {
      setLoading(true);
      const file = fileObject;
      const formData = new FormData();
      formData.append('title', title);
      formData.append('image', file);

      if (banner?._id) {
        await dispatch(updateBanner({ id: banner._id, formData }));
      } else {
        await dispatch(addBanner(formData)).unwrap();
      }
      setFileObject({});
      setTitle('');
      setLoading(false);
      navigate('/dashboard/banner');
    } catch (error) {
      //message.error('Error while adding banner')
    }
  };
  return (
    <>
      <GoBack linkUrl={'/dashboard/banner'} />
      <StyledLayout>
        <Title level={4} style={{ marginBottom: '30px' }}>
          {banner?._id ? 'Edit Banner' : 'Add New Banner'}
        </Title>
        <form
          onSubmit={(e) => {
            handleFinish(e);
          }}
        >
          <TextField
            label="Title"
            variant="outlined"
            required
            sx={{ width: '400px', marginBottom: '30px' }}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
          />
          <UploadImage
            setFileObject={setFileObject}
            defaultImage={banner?.image}
          />
          <Box sx={{ marginTop: '30px' }}>
            <Button
              type="primary"
              htmlType="submit"
              className="button"
              loading={loading}
            >
              {banner?._id ? 'Edit' : 'Submit'}
            </Button>
            <Button variant="outlined" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </Box>
        </form>
      </StyledLayout>
    </>
  );
}

export default NewBanner;

import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'underscore';
import {
  getEventDiscounts,
  deleteEventDiscount,
} from 'redux/actions/eventDiscountActions';

const eventDiscountsAdapter = createEntityAdapter({
  selectId: (eventDiscount) => eventDiscount._id,
});
const eventDiscountsReducer = createSlice({
  name: 'eventDiscount',
  initialState: eventDiscountsAdapter.getInitialState({
    loading: true,
    error: null,
    allEventDiscountsListCount: 0,
    filters: {
      limit: 50,
      sortBy: '',
      searchKey: '',
    },
  }),
  reducers: {
    setFilters: (state, action) => {
      const filterUpdates = action.payload;
      Object.keys(filterUpdates).forEach((filterKey) => {
        if (!_.isEqual(state.filters[filterKey], action.payload[filterKey])) {
          if (filterKey !== 'skip' && filterKey !== 'current') {
            filterUpdates.current = 1;
            filterUpdates.skip = 0;
          }
          state.filters[filterKey] = action.payload[filterKey];
        }
      });
    },
  },
  extraReducers: {
    [getEventDiscounts.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getEventDiscounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.allEventDiscountsListCount = action.payload.count;
      eventDiscountsAdapter.upsertMany(state, action.payload.discounts);
      state.ids = action.payload.discounts.map(
        (eventDiscount) => eventDiscount._id
      );
    },
    [getEventDiscounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [deleteEventDiscount.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteEventDiscount.fulfilled]: (state, action) => {
      state.loading = false;
      eventDiscountsAdapter.removeOne(state, action.meta.arg.id);
      state.allEventDiscountsListCount = action.payload.count - 1;
    },
    [deleteEventDiscount.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});
export const selectEventDiscountLoading = (state) =>
  state.eventDiscounts.loading;
export const selectEventDiscountCount = createSelector(
  [(state) => state.eventDiscounts.allEventDiscountsListCount],
  (count) => count
);
export const {
  selectAll: selectAllEventDiscounts,
  selectById: selectEventDiscountsById,
} = eventDiscountsAdapter.getSelectors((state) => state.eventDiscounts);
export const { setFilters } = eventDiscountsReducer.actions;
export default eventDiscountsReducer.reducer;

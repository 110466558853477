/* eslint-disable max-lines */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Form,
  Input,
  InputNumber,
  Button,
  Typography,
  Select,
  Checkbox,
  Radio,
  Space,
  message,
} from 'antd';
import { addProduct, updateProduct } from 'redux/actions/productActions';
import { getAllCategories } from 'redux/actions/categoryActions';
import { getAllAdditionalCategories } from 'redux/actions/additionalCategoryActions';
import { StyledLayout } from '../../../components/StyledLayout/Layout.styled';
import { addSizes, formatedProduct } from './NewProduct.helper';
import UploadImage from '../../../components/Upload';
import GoBack from '../../../components/GoBack';
import { getstoreLocation } from 'redux/actions/storeLocationActions';
const { Title } = Typography;
const { Option } = Select;

const NewProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const { product } = location?.state || {};
  const [product, setProduct] = useState(location?.state?.product || {});
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [additionalCategory, setAdditionalCategory] = useState([]);
  const [fileObject, setFileObject] = useState({});
  const [loading, setLoading] = useState(false);
  const [sendNotification, setNotification] = useState(false);

  const [form] = Form.useForm();
  const getDropdownValues = useCallback(async () => {
    try {
      dispatch(getstoreLocation()).then((res) => {
        const location = res?.payload?.storeLocations?.map((item) => ({
          label: item?.name,
          value: item?._id,
        }));
        setLocations(location);
      });
      dispatch(getAllAdditionalCategories()).then((res) => {
        const list = res?.payload?.categories.map((category) => ({
          label: category?.name['en'],
          value: category?._id,
        }));
        setAdditionalCategory(list);
      });
    } catch (err) {
      message.error('Something went wrong');
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllCategories()).then((res) => {
      const list = res?.payload.map((item) => ({
        label: item?.name['en'],
        value: item?._id,
      }));
      setCategories(list);
    });
  }, [dispatch]);

  useEffect(() => {
    getDropdownValues();
  }, [dispatch, getDropdownValues]);

  const onSendNotification = (e) => {
    setNotification(e.target.checked);
  };

  const handleFinish = async (values) => {
    setLoading(true);
    const file = typeof values?.image === String ? values?.image : fileObject;
    const formData = new FormData();
    formData.append('sendNotification', sendNotification);
    formData.append(
      'name',
      JSON.stringify({
        en: values['name.en'],
        ar: values['name.ar'],
      })
    );
    formData.append(
      'description',
      JSON.stringify({
        en: values['description.en'],
        ar: values['description.ar'],
      })
    );
    formData.append('price', values?.price);
    if (values?.additionalCategory?.length > 0) {
      formData.append(
        'additionalCategories[]',
        JSON.stringify(values?.additionalCategory)
      );
    }
    formData.append('categoryId', values?.category);
    formData.append('storeLocation[]', JSON.stringify(values?.storeLocation));

    formData.append('image', file);
    formData.append('color', values?.color);
    if (values?.status) formData.append('status', values?.status);

    if (values?.sizes)
      formData.append('sizes[]', JSON.stringify(values?.sizes));
    if (values?.categories)
      formData.append('categories[]', JSON.stringify(values?.categories));
    if (product?._id) {
      try {
        const response = await dispatch(
          updateProduct({ formData, id: product?._id })
        ).unwrap();
        setProduct(response.product);
        message.success('The product has been updated');
      } catch (err) {
        message.error('somthing wrong try again later');
      }
    } else {
      await dispatch(addProduct(formData)).unwrap();
      form.resetFields();
      navigate('/dashboard/product');
    }
    setLoading(false);
  };
  const formatProduct = formatedProduct(product, locations);
  return (
    <>
      <GoBack linkUrl={'/dashboard/product'} />
      <StyledLayout>
        <Title level={4}>
          {product?._id ? 'update product' : 'Create new product'}
        </Title>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          initialValues={formatProduct}
        >
          <Form.Item label="Image" name="image">
            <UploadImage
              setFileObject={setFileObject}
              defaultImage={product?.image}
            />
          </Form.Item>
          <Form.Item label="Name" name="name.en" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          {/* <Form.Item
            label="Name in arabic"
            name="name.ar"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item> */}
          <Form.Item
            label="Background color"
            name="color"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Store Location"
            name="storeLocation"
            rules={[{ required: true }]}
            defaultValue={formatProduct.storeLocation || locations}
          >
            <Select
              mode="multiple"
              allowClear
              style={{
                width: '100%',
              }}
            >
              {locations?.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Category"
            name="category"
            rules={[{ required: true }]}
            defaultValue={formatProduct.category}
          >
            <Select style={{ width: '100%' }}>
              {categories?.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Add Ons (optional)"
            name="additionalCategory"
            rules={[{ required: false }]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{
                width: '100%',
              }}
              // onChange={handleChange}
            >
              {additionalCategory?.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Ingredients" name="description.en">
            <Input />
          </Form.Item>
          {/* <Form.Item label="Ingredients in Arabic" name="description.ar">
            <Input />
          </Form.Item> */}

          <Form.Item name="price" label="Price" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          {addSizes(Form)}
          <Form.Item name="status" label="Status">
            <Radio.Group defaultValue={formatProduct?.status || 'Enabled'}>
              <Space direction="vertical">
                <Radio value={'Enabled'}>Enabled</Radio>
                <Radio value={'Disabled'}>Disabled</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="sendNotification">
            <Checkbox onChange={onSendNotification}>
              Send notification to all
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="button"
              loading={loading}
            >
              {product?._id ? 'update' : 'submit'}
            </Button>
            <Button
              type="secondary"
              htmlType="button"
              className="cancelButton"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </StyledLayout>
    </>
  );
};

NewProduct.propTypes = {
  children: PropTypes.node,
};

export default NewProduct;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { dashboardAPIs } from 'services/api';
import axiosInstance from 'services/apiService';

export const getSalesSummary = createAsyncThunk(
  'admin/getSalesSummary',
  async () => {
    const { data } = await axiosInstance({
      ...dashboardAPIs.salesSummary,
    });
    return data;
  }
);

export const getSalesHistory = createAsyncThunk(
  'admin/getSalesHistory',
  async () => {
    const { data } = await axiosInstance({
      ...dashboardAPIs.salesHistory,
    });
    return data;
  }
);

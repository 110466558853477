/* eslint-disable max-lines */
import React from 'react';
import { Link } from 'react-router-dom';
import { dateFormat } from 'configs';
import { Space, Tooltip } from 'antd';
import {
  StyledReadOutlined,
  StyledPendingStatus,
  StyledPreparingStatus,
  StyledCollectedStatus,
  StyledRejectedStatus,
} from './listing.styles';
const ExcelJS = require('exceljs');

export const statusStyle = (status) => {
  switch (status) {
    case 'Pending':
      return <StyledPendingStatus>{status}</StyledPendingStatus>;

    case 'Preparing':
      return <StyledPreparingStatus>{status}</StyledPreparingStatus>;

    case 'Collected':
      return <StyledCollectedStatus>{status}</StyledCollectedStatus>;

    case 'Rejected':
      return <StyledRejectedStatus>{status}</StyledRejectedStatus>;

    case 'Cancelled':
      return <StyledRejectedStatus>{status}</StyledRejectedStatus>;

    default:
      return <StyledPreparingStatus>{status}</StyledPreparingStatus>;
  }
};
export const orderTableColumn = () => [
  {
    title: 'Order Id',
    render: (order) => <>{order?._id.substr(17, 24)}</>,
    width: 40,
    key: '_id',
    align: 'center',
  },
  {
    title: 'Customer',
    render: (order) => (
      <>
        {order?.customerId.firstname || order?.customerId?.email}{' '}
        {order?.customerId.lastname}{' '}
      </>
    ),
    width: 50,
    key: 'customerId.fullname',
    align: 'center',
  },
  {
    title: 'Pickup date and time',
    key: 'createdAt',
    width: 60,
    render: (row) => (
      <>
        {row?.pickUpDateTime
          ? dateFormat(row?.pickUpDateTime)
          : dateFormat(row.createdAt)}
      </>
    ),
    align: 'center',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
  },
  {
    title: 'Delivery Pricing',
    render: (order) => <b>${order?.total}</b>,

    width: 30,
    key: 'amount',
    align: 'center',
    sorter: (a, b) => a.total < b.total,
  },
  {
    title: 'Store Location',
    render: (order) => <b>{order?.storeLocation?.name}</b>,
    width: 30,
    key: 'location',
    align: 'center',
  },
  {
    title: 'Delivery Status',
    render: (order) => statusStyle(order?.status),
    width: 30,
    key: 'status',
    align: 'center',
  },
  {
    title: 'Action',
    key: 'action',
    width: 40,
    align: 'center',
    fixed: 'right',
    render: (order) => (
      <Space size="middle">
        <Link
          className="bold"
          state={{ orderId: order?._id }}
          to="/dashboard/orderDetail"
        >
          <Tooltip title="Detail">
            <StyledReadOutlined />
          </Tooltip>
        </Link>
      </Space>
    ),
  },
];

export const exportExcelFile = (data, name) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('My Sheet');
  sheet.properties.defaultRowHeight = 20;
  sheet.properties.defaultCellStyle = {};
  sheet.properties.defaultCellStyle.alignment = { horizontal: 'left' };

  sheet.getRow(1).font = {
    name: 'roboto',
    family: 4,
    size: 16,
    bold: true,
  };

  sheet.columns = [
    {
      header: 'Order id',
      key: 'id',
      width: 20,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Customer',
      key: 'customer',
      width: 22,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Pickup date and time',
      key: 'pickup',
      width: 30,
      style: {
        alignment: {
          horizontal: 'left',
        },
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Delivery Pricing',
      key: 'delivery',
      width: 22,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Store Location',
      key: 'location',
      width: 32,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Delivery Status',
      key: 'status',
      width: 12,
      style: {
        font: {
          size: 12,
        },
      },
    },
  ];

  const promise = Promise.all(
    data.map((item) => {
      sheet.addRow({
        id: item?._id.substr(17, 24),
        customer: `${item?.customerId?.firstname} ${item?.customerId?.lastname}`,
        pickup: item?.pickUpDateTime,
        delivery: item?.total,
        location: item?.storeLocation?.name,
        status: item?.status,
      });
      return null;
    })
  );

  promise.then(() => {
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = name?.toString() || 'download.xlsx';
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  });
};

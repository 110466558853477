import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getSkipCount } from 'utils';
import { Card, Alert } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { orderTableColumn, exportExcelFile } from './listing.helper';
import { debounce } from 'lodash';
import { StyledExportButton } from './listing.styles';
import { TableView } from '../../../components/StyledLayout/Layout.styled';
import SearchHeader from '../../../components/SearchHeader';
import { getOrders, getUserOrders } from 'redux/actions/orderActions';
import {
  selectAllOrders,
  selectOrderCount,
  selectOrderLoading,
} from 'redux/reducers/orderReducer';
import { useSocket } from '../../../providers/SocketProvider';
import NotificationSound from './notification-sound.wav';
const newOrderAlert = {
  body: 'Hey you received a new order. Go to dashboard for more detail.',
  title: 'New Order Received',
};

const Listing = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const socket = useSocket();
  const audioPlayer = useRef(null);

  const allOrders = useSelector(selectAllOrders);
  const isLoading = useSelector(selectOrderLoading);
  const allOrdersListCount = useSelector(selectOrderCount);
  const { userId } = location?.state || {};

  const initialPageConfig = {
    current: 1,
    pageSize: 10,
    limit: 10,
    skip: getSkipCount(1, 10),
  };
  const [pageConfig, setPageConfig] = useState(initialPageConfig);
  const onChange = (pagination, filters) => {
    const newPageConfig = {
      current: pagination.current,
      pageSize: pagination.pageSize,
      limit: pagination.pageSize,
      skip: getSkipCount(pagination.current, pagination.pageSize),
      status: filters?.status ? filters.status[0] : '',
      userType: filters?.userType ? filters.userType[0] : '',
      searchKey: pageConfig?.searchKey,
    };
    setPageConfig(newPageConfig);
  };

  const tableRef = useRef(null);

  useEffect(() => {
    if (userId) {
      const body = {
        id: userId,
      };
      dispatch(getUserOrders(body));
    } else dispatch(getOrders(pageConfig));
  }, [dispatch, pageConfig, userId]);

  const showPreOrders = async () => {
    const newPageConfig = {
      current: 1,
      pageSize: 10,
      limit: 10,
      skip: getSkipCount(1, 10),
      type: pageConfig?.type === 'Pre' ? null : 'Pre',
    };
    setPageConfig(newPageConfig);
  };

  const playAudio = () => {
    audioPlayer.current.play();
  };

  const showNotification = useCallback(function callback(data) {
    if (!('Notification' in window)) {
      Alert('Browser does not support desktop notification');
    } else {
      Notification.requestPermission();
    }
    var options = {
      body: data.body,
      dir: 'ltr',
    };

    new Notification(data.title, options);
    playAudio();
  }, []);

  useEffect(() => {
    if (userId) {
      socket?.on('newOrder', () => {
        const body = {
          id: userId,
        };
        dispatch(getUserOrders(body));
        showNotification(newOrderAlert);
      });
    } else {
      socket?.on('newOrder', () => {
        dispatch(getOrders(pageConfig));
        showNotification(newOrderAlert);
      });
    }
  }, [userId, dispatch, socket, pageConfig, showNotification]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = debounce((e) => {
    setPageConfig({
      ...pageConfig,
      searchKey: e.target.value,
    });
  }, 500);

  const ordersColumns = orderTableColumn();

  return (
    <>
      <SearchHeader handleSearch={handleSearch} />
      <Card
        title={
          <span>
            Orders{' '}
            <span
              style={{ color: 'blue', fontSize: '13px', cursor: 'pointer' }}
              onClick={() => showPreOrders()}
            >
              {pageConfig?.type === 'Pre'
                ? '(List All Orders)'
                : '(List only Pre Orders)'}
            </span>
          </span>
        }
        bordered={true}
        extra={
          <>
            <StyledExportButton
              onClick={() => {
                exportExcelFile(allOrders, 'order');
              }}
            >
              <DownloadOutlined /> Export{' '}
            </StyledExportButton>
          </>
        }
      >
        <TableView
          ref={tableRef}
          columns={ordersColumns}
          dataSource={allOrders}
          pagination={{
            position: ['none', 'bottomRight'],
            total: allOrdersListCount,
            showSizeChanger: false,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} Orders`,
            showQuickJumper: true,
            defaultPageSize: 50,
            ...pageConfig,
          }}
          scroll={{ x: 1000 }}
          onChange={onChange}
          loading={isLoading}
          bordered={true}
        />
      </Card>
      <audio ref={audioPlayer} src={NotificationSound} />
    </>
  );
};
Listing.propTypes = {};

export default Listing;

import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'underscore';
import { getUsers } from 'redux/actions/usersActions';

const userAdapter = createEntityAdapter({
  selectId: (user) => user._id,
});
const userReducer = createSlice({
  name: 'user',
  initialState: userAdapter.getInitialState({
    loading: true,
    error: null,
    allUsersListCount: 0,
    filters: {
      limit: 50,
      sortBy: '',
      searchKey: '',
    },
  }),
  reducers: {
    setFilters: (state, action) => {
      const filterUpdates = action.payload;
      Object.keys(filterUpdates).forEach((filterKey) => {
        if (!_.isEqual(state.filters[filterKey], action.payload[filterKey])) {
          if (filterKey !== 'skip' && filterKey !== 'current') {
            filterUpdates.current = 1;
            filterUpdates.skip = 0;
          }
          state.filters[filterKey] = action.payload[filterKey];
        }
      });
    },
  },
  extraReducers: {
    [getUsers.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.allUsersListCount = action.payload.count;
      userAdapter.upsertMany(state, action.payload.users);
      state.ids = action.payload.users.map((user) => user._id);
    },
    [getUsers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});
export const selectUsersLoading = (state) => state.users.loading;
export const selectUsersCount = createSelector(
  [(state) => state.users.allUsersListCount],
  (count) => count
);
export const { selectAll: selectAllUsers, selectById: selectUserById } =
  userAdapter.getSelectors((state) => state.users);
export const { setFilters } = userReducer.actions;
export default userReducer.reducer;

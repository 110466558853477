import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'underscore';
import {
  getOrders,
  deleteOrder,
  getUserOrders,
  getOrderDetail,
} from 'redux/actions/orderActions';

const orderAdapter = createEntityAdapter({
  selectId: (order) => order._id,
});
const orderReducer = createSlice({
  name: 'order',
  initialState: orderAdapter.getInitialState({
    loading: true,
    error: null,
    allOrdersListCount: 0,
    filters: {
      limit: 50,
      sortBy: '',
      searchKey: '',
    },
  }),
  reducers: {
    setFilters: (state, action) => {
      const filterUpdates = action.payload;
      Object.keys(filterUpdates).forEach((filterKey) => {
        if (!_.isEqual(state.filters[filterKey], action.payload[filterKey])) {
          if (filterKey !== 'skip' && filterKey !== 'current') {
            filterUpdates.current = 1;
            filterUpdates.skip = 0;
          }
          state.filters[filterKey] = action.payload[filterKey];
        }
      });
    },
  },
  extraReducers: {
    [getOrders.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getOrders.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.allOrdersListCount = action.payload.count;
      orderAdapter.upsertMany(state, action.payload.orders);
      state.ids = action.payload.orders.map((order) => order._id);
    },
    [getOrders.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getUserOrders.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getUserOrders.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.allOrdersListCount = action.payload.count;
      orderAdapter.upsertMany(state, action.payload.orders);
      state.ids = action.payload.orders.map((order) => order._id);
    },
    [getUserOrders.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getOrderDetail.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getOrderDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      orderAdapter.upsertMany(state, action.payload.order);
    },
    [getOrderDetail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [deleteOrder.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteOrder.fulfilled]: (state, action) => {
      state.loading = false;
      orderAdapter.removeOne(state, action.meta.arg.id);
      state.allOrdersListCount = action.payload.count - 1;
    },
    [deleteOrder.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});
export const selectOrderLoading = (state) => state.order.loading;
export const selectOrderCount = createSelector(
  [(state) => state.order.allOrdersListCount],
  (count) => count
);
export const { selectAll: selectAllOrders, selectById: selectOrderById } =
  orderAdapter.getSelectors((state) => state.order);

export const { setFilters } = orderReducer.actions;
export default orderReducer.reducer;

import React from 'react';
import { Link } from 'react-router-dom';
import style from './banner.module.css';
// icons && MUI
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Box from '@mui/material/Box';
// antd
import { Image, Popconfirm, Space, Tooltip } from 'antd';

export const BannerTableColumn = (confirmDelete, onOrderChange) => {
  return [
    {
      title: 'No',
      render: (banner) => <>{banner?.order}</>,
      width: 70,
      key: 'index',
      align: 'center',
    },
    {
      title: 'Title',
      render: (banner) => <>{banner?.title}</>,
      width: 70,
      key: 'title',
      align: 'center',
    },
    {
      title: 'Banner',
      width: 100,
      key: 'Banner',
      align: 'center',
      render: (banner) => {
        return (
          <>
            <BannerImage url={banner.image} />
          </>
        );
      },
    },
    {
      title: 'Action',
      width: 50,
      key: 'Action',
      align: 'center',
      render: (banner) => {
        return (
          <>
            <BannerAction banner={banner} confirmDelete={confirmDelete} />
          </>
        );
      },
    },
    {
      title: 'Order',
      width: 50,
      key: 'Order',
      align: 'center',
      render: (banner) => {
        return (
          <>
            <BannerOrder banner={banner} onOrderChange={onOrderChange} />
          </>
        );
      },
    },
  ];
};

export const BannerImage = (prop) => {
  return (
    <>
      <Image src={prop?.url} height={80} />
    </>
  );
};

export const BannerAction = (prop) => {
  const banner = prop?.banner;
  const { confirmDelete } = prop;
  return (
    <>
      <Space size="middle">
        <Popconfirm
          placement="topRight"
          title="Are you sure! delete this banner?"
          onConfirm={() => confirmDelete(banner)}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title="Delete">
            <DeleteOutlinedIcon sx={{ color: 'red' }} />
          </Tooltip>
        </Popconfirm>
        <Tooltip title="Edit">
          <Link
            className="bold"
            state={{ banner: banner }}
            to="/dashboard/addBanner"
          >
            <BorderColorOutlinedIcon style={{ color: 'blue' }} />
          </Link>
        </Tooltip>
      </Space>
    </>
  );
};

export const BannerOrder = (prop) => {
  const { banner, onOrderChange } = prop;
  return (
    <>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <KeyboardArrowUpOutlinedIcon
          className={`${style.btn_hover_circle} ${style.pointer}`}
          onClick={() => {
            onOrderChange(banner, 'up');
          }}
        />

        <KeyboardArrowDownOutlinedIcon
          className={`${style.btn_hover_circle}  ${style.pointer}`}
          onClick={() => {
            onOrderChange(banner, 'down');
          }}
        />
      </Box>
    </>
  );
};

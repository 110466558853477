import { createAsyncThunk } from '@reduxjs/toolkit';
import { bannerAPISs } from 'services/api';
import axiosInstance from 'services/apiService';

export const addBanner = createAsyncThunk('banner/add', async (payload) => {
  const { data } = await axiosInstance({ ...bannerAPISs.add, data: payload });
  return data;
});

export const deleteBanner = createAsyncThunk(
  'banner/deleteProduct',
  async ({ bannerId }) => {
    const { data } = await axiosInstance({
      ...bannerAPISs.delete,
      url: `banner/${bannerId}/`,
    });
    return data;
  }
);

export const getBanners = createAsyncThunk(
  'banner/getProducts',
  async (payload) => {
    const { data } = await axiosInstance({
      ...bannerAPISs.get,
      params: payload,
    });
    return data;
  }
);

export const updateBanner = createAsyncThunk(
  'banner/update',
  async (payload) => {
    const { data } = await axiosInstance({
      ...bannerAPISs.update,
      url: `banner/${payload.id}/`,
      data: payload.formData,
    });
    return data;
  }
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeLocationAPIs } from 'services/api';
import axiosInstance from 'services/apiService';

export const addstoreLocation = createAsyncThunk(
  'storeLocation/add',
  async (payload) => {
    const { data } = await axiosInstance({
      ...storeLocationAPIs.add,
      data: payload,
    });
    return data;
  }
);

export const deleteStoreLocation = createAsyncThunk(
  'storeLocation/delete',
  async ({ id }) => {
    const api = storeLocationAPIs.delete;
    const { data } = await axiosInstance({
      ...api,
      url: `storeLocation/${id}/`,
    });
    return data.message;
  }
);

export const getstoreLocation = createAsyncThunk(
  'storeLocation/get',
  async (payload) => {
    const { data } = await axiosInstance({
      ...storeLocationAPIs.get,
      params: payload,
    });
    return data;
  }
);

export const updatestoreLocation = createAsyncThunk(
  'storeLocation/update',
  async (payload) => {
    const api = storeLocationAPIs.update;

    const { data } = await axiosInstance({
      ...api,
      url: `storeLocation/${payload._id}/`,
      data: payload.values,
    });
    return data;
  }
);

import moment from 'moment';

export const formatedEvent = (event, categories) => {
  return {
    'name.en': event?.name?.en,
    'name.ar': event?.name?.ar,
    'description.en': event?.description?.en,
    'description.ar': event?.description?.ar,
    discountedCategories: event?.discountedCategories,
    discountPercentage: event?.discountPercentage * 100,
    startDate: moment(event?.startDate),
    endDate: moment(event?.endDate),
  };
};

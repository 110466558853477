import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Typography,
  message,
  InputNumber,
  DatePicker,
  Select,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  addEventDiscounts,
  updateEventDiscounts,
} from 'redux/actions/eventDiscountActions';
import { getAllCategories } from 'redux/actions/categoryActions';
import { StyledLayout } from 'components/StyledLayout/Layout.styled';
import GoBack from 'components/GoBack';
import { formatedEvent } from './NewEvent.helper';

const { Title } = Typography;
const { Option } = Select;

const NewEvent = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const location = useLocation();
  const { eventDiscounts } = location?.state || {};

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    dispatch(getAllCategories()).then((res) => {
      const list = res?.payload.map((item) => ({
        label: item?.name['en'] + ' (' + item?.name['ar'] + ')',
        value: item?._id,
      }));
      setCategories(list);
    });
  }, [dispatch]);

  const handleFinish = async (values) => {
    try {
      setLoading(true);
      const dataObject = {
        name: {
          en: values['name.en'],
          ar: values['name.ar'],
        },
        description: {
          en: values['description.en'],
          ar: values['description.ar'],
        },
        discountedCategories: values.discountedCategories,
        discountPercentage: values.discountPercentage,
        startDate: new Date(values.startDate),
        endDate: new Date(values.endDate),
      };
      if (eventDiscounts?._id) {
        await dispatch(
          updateEventDiscounts({ values: dataObject, _id: eventDiscounts?._id })
        ).unwrap();
      } else {
        await dispatch(addEventDiscounts(dataObject)).unwrap();
      }

      form.resetFields();
      setLoading(false);
      navigate('/dashboard/eventsDiscount');
    } catch (e) {
      setLoading(false);
      message.error(e.message || 'Error while adding event discount');
    }
  };

  const disabledDate = (current) => {
    let today = new Date();
    today.setDate(today.getDate() - 1);

    if (new Date(current) < today) {
      return true;
    }
    return false;
  };
  const eventDiscount = formatedEvent(eventDiscounts, categories);

  return (
    <>
      <GoBack linkUrl="/dashboard/eventsDiscount" />
      <StyledLayout>
        <Title level={4}>Add new event discount</Title>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          className="formView"
          initialValues={eventDiscount}
        >
          <Form.Item label="Name" name="name.en" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          {/* <Form.Item
            label="Name in arabic"
            name="name.ar"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item> */}
          <Form.Item label="Description" name="description.en">
            <Input />
          </Form.Item>
          {/* <Form.Item label="Description in arabic" name="description.ar">
            <Input />
          </Form.Item> */}
          <Form.Item
            label="Discounted Categories"
            name="discountedCategories"
            rules={[{ required: true }]}
          >
            <Select style={{ width: '100%' }} mode="multiple" allowClear>
              {categories?.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Discount percentage"
            name="discountPercentage"
            rules={[{ required: true }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace('%', '')}
            />
          </Form.Item>
          <Form.Item
            label="Start Date"
            name="startDate"
            rules={[{ required: true }]}
          >
            <DatePicker
              disabledDate={disabledDate}
              // defaultValue={moment(eventDiscount?.startDate)}
              format={'YYYY/MM/DD'}
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
          <Form.Item
            label="End Date"
            name="endDate"
            rules={[{ required: true }]}
          >
            <DatePicker
              disabledDate={disabledDate}
              // defaultValue={moment(eventDiscount?.endDate)}
              format={'YYYY/MM/DD'}
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="button"
              loading={loading}
            >
              Submit
            </Button>
            <Button
              type="secondary"
              htmlType="button"
              className="cancelButton"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </StyledLayout>
    </>
  );
};

export default NewEvent;

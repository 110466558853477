import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';
import { useSelector } from 'react-redux';
import Layout from 'layouts';
import Login from 'modules/login';
import Dashboard from 'modules/dashboard';
import CategoryListing from 'modules/category/CategoryListing';
import AddCategory from 'modules/category/AddCategory';
import ProductListing from 'modules/product/ProductListing';
import AddProduct from 'modules/product/AddProduct';
import Admins from 'modules/admins/AdminListing';
import AddAdminUser from 'modules/admins/InviteAdmin';
import NotificationListing from 'modules/notifications/NotificationListing';
import AddNotifications from 'modules/notifications/AddNotification';
import SendNotifications from 'modules/notifications/SendNotification';
import OrdersListing from 'modules/orders/OrdersListing';
import OrderDetail from 'modules/orders/OrderDetail';
import ContactData from 'modules/contactData';
import AddAdditionalCategory from 'modules/additionalCategory/AddCategory';
import AdditionalCategoryLisiting from 'modules/additionalCategory/CategoryListing';
import CustomerLising from 'modules/customers/CustomerListing';
import CustomerDetail from 'modules/customers/CustomerDetail';
import LogsListings from 'modules/logs';
import EventDiscountListing from 'modules/eventDiscounts/EventLisitng';
import AddEventDiscount from 'modules/eventDiscounts/AddEvent';
import StoreLocationListing from 'modules/storeLocations/StoreLocationLisitng';
import AddStoreLocation from 'modules/storeLocations/AddStoreLocation';
// add banner section
import BannerListing from '../modules/banner/BannerListing';
import NewBanner from 'modules/banner/AddBanner';

function AppRoutes() {
  const authToken = useSelector((store) => store.auth.authToken);
  const isLoggedIn = !!authToken;

  return (
    <BrowserRouter>
      <Routes>
        {isLoggedIn ? (
          <>
            <Route path="dashboard" element={<Layout />}>
              <Route index element={<Dashboard />} />
              <Route path="category" element={<CategoryListing />} />
              <Route path="addCategory" element={<AddCategory />} />
              <Route path="product" element={<ProductListing />} />
              <Route path="adminUser" element={<Admins />} />
              <Route path="addAdminUser" element={<AddAdminUser />} />
              <Route path="addProduct" element={<AddProduct />} />
              <Route path="notifications" element={<NotificationListing />} />
              <Route path="addNotifications" element={<AddNotifications />} />
              <Route path="sendNotifications" element={<SendNotifications />} />
              <Route path="orders" element={<OrdersListing />} />
              <Route path="OrderDetail" element={<OrderDetail />} />
              <Route path="contactData" element={<ContactData />} />
              <Route path="banner" element={<BannerListing />} />
              <Route path="addBanner" element={<NewBanner />} />
              <Route
                path="addAdditionalCategory"
                element={<AddAdditionalCategory />}
              />
              <Route
                path="additionalCategory"
                element={<AdditionalCategoryLisiting />}
              />
              <Route path="customers" element={<CustomerLising />} />
              <Route path="customerDetail" element={<CustomerDetail />} />
              <Route path="logs" element={<LogsListings />} />
              <Route path="eventsDiscount" element={<EventDiscountListing />} />
              <Route path="addEventsDiscount" element={<AddEventDiscount />} />
              <Route path="storeLocation" element={<StoreLocationListing />} />
              <Route path="addStoreLocation" element={<AddStoreLocation />} />
            </Route>
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </>
        ) : (
          <>
            <Route path="login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}{' '}
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;

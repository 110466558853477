export const adminAPIs = {
  get: {
    url: 'admin/user',
    method: 'get',
    isAuthEnabled: true,
  },
  add: {
    url: 'admin/auth',
    method: 'post',
    isAuthEnabled: true,
  },
  delete: {
    url: 'admin/user',
    method: 'delete',
    isAuthEnabled: true,
  },
  updateStatus: {
    url: 'admin/user/status',
    method: 'post',
    isAuthEnabled: true,
  },
};

/* eslint-disable max-lines */
import React from 'react';
import { dateFormat } from 'configs';
const ExcelJS = require('exceljs');

export const logsColumns = () => [
  {
    title: 'Name',
    render: (item) => <>{item?.user?.fullname}</>,
    width: 80,
    key: 'name',
    align: 'center',
  },
  {
    title: 'Email',
    render: (item) => <>{item?.user?.email}</>,
    width: 50,
    key: 'description',
    align: 'center',
  },
  {
    title: 'Role',
    render: (item) => <b>{item?.user?.role}</b>,
    key: 'price',
    width: 30,
    align: 'center',
  },
  {
    title: 'Status',
    dataIndex: 'action',
    key: 'status',
    width: 30,
    align: 'center',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 120,
    align: 'center',
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 70,
    render: (row) => dateFormat(row),
    align: 'center',
  },
];

export const exportExcelFile = (data, name) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('My Sheet');
  sheet.properties.defaultRowHeight = 20;
  sheet.properties.defaultCellStyle = {};
  sheet.properties.defaultCellStyle.alignment = { horizontal: 'left' };

  sheet.getRow(1).font = {
    name: 'roboto',
    family: 4,
    size: 16,
    bold: true,
  };

  sheet.columns = [
    {
      header: 'Name',
      key: 'name',
      width: 20,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Email',
      key: 'email',
      width: 42,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Role',
      key: 'role',
      width: 20,
      style: {
        alignment: {
          horizontal: 'left',
        },
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Status',
      key: 'status',
      width: 30,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Description',
      key: 'description',
      width: 80,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Created Date',
      key: 'createdAt',
      width: 30,
      style: {
        font: {
          size: 12,
        },
      },
    },
  ];

  const promise = Promise.all(
    data.map((item) => {
      sheet.addRow({
        name: item?.user?.fullname,
        email: item?.user?.email,
        role: item?.user?.role,
        status: item?.action,
        description: item?.description,
        createdAt: item?.createdAt,
      });
      return null;
    })
  );

  promise.then(() => {
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = name?.toString() || 'download.xlsx';
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  });
};

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Typography, message, Select, Checkbox } from 'antd';
import { sendNotification, getUsers } from 'redux/actions/notificationActions';
import { selectAllNotifications } from 'redux/reducers/notificationReducer';
import GoBack from '../../../components/GoBack';
import { StyledLayout } from '../../../components/StyledLayout/Layout.styled';

const { Title } = Typography;
const { Option } = Select;

const SendNotification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [allUsers, checkAllUsers] = useState(false);
  const allNotifications = useSelector(selectAllNotifications);

  const [form] = Form.useForm();
  useEffect(() => {
    dispatch(getUsers()).then((res) => {
      const list = res?.payload?.user.map((user) => ({
        label: `${user.firstname} ${user.lastname}(${user?.email})`,
        value: user?._id,
      }));
      setUsers(list);
    });
  }, [dispatch]);

  const handleFinish = async (values) => {
    try {
      values.users = allUsers ? users : values.users;
      if (allUsers) values.notificationType = 'all';
      await dispatch(sendNotification(values)).unwrap();
      form.resetFields();
      navigate('/dashboard/notifications');
    } catch (e) {
      message.error('Error while adding Notifications');
    }
  };

  const onCheckboxChange = (e) => {
    checkAllUsers(e.target.checked);
  };

  return (
    <>
      <GoBack linkUrl={'/dashboard/notifications'} />
      <StyledLayout>
        <Title level={4}>Send notification</Title>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Form.Item
            label="Select notification type"
            name="type"
            rules={[{ required: true }]}
          >
            <Select style={{ width: '100%' }}>
              {allNotifications.map((item) => (
                <Option key={item._id} value={item._id}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="sendNotification">
            <Checkbox onChange={onCheckboxChange}>
              Send notification to all users
            </Checkbox>
          </Form.Item>

          <Form.Item
            label="Select users"
            name="users"
            rules={[{ required: allUsers ? false : true }]}
          >
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              disabled={allUsers}
              showSearch
              optionFilterProp="children"
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
              }
            >
              {users.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="button">
              Send
            </Button>
            <Button
              type="secondary"
              htmlType="button"
              className="cancelButton"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </StyledLayout>
    </>
  );
};

export default SendNotification;

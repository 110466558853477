import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { getSkipCount } from 'utils';
import {
  TableView,
  StyledExportButton,
} from 'components/StyledLayout/Layout.styled';
import SearchHeader from 'components/SearchHeader';
import { logsColumns, exportExcelFile } from './logs.helper';
import { getLogs } from 'redux/actions/logActions';
import {
  selectAllLogs,
  selectLogsCount,
  selectLogsLoading,
  setFilters,
} from 'redux/reducers/logReducer';

const LogsListing = () => {
  const dispatch = useDispatch();

  const allLogs = useSelector(selectAllLogs);
  const isLoading = useSelector(selectLogsLoading);
  const allLogsListCount = useSelector(selectLogsCount);

  const initialPageConfig = {
    current: 1,
    pageSize: 10,
    limit: 10,
    skip: getSkipCount(1, 10),
    role: 'admin',
  };
  const [pageConfig, setPageConfig] = useState(initialPageConfig);

  const onChange = (pagination, filters) => {
    const newPageConfig = {
      current: pagination.current,
      pageSize: pagination.pageSize,
      limit: pagination.pageSize,
      skip: getSkipCount(pagination.current, pagination.pageSize),
      status: filters?.status ? filters.status[0] : '',
      role: 'admin',
    };
    setPageConfig(newPageConfig);

    dispatch(setFilters(newPageConfig));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getLogs(pageConfig));
  }, [dispatch, pageConfig]);

  const logColumns = logsColumns();

  const tableRef = useRef(null);

  return (
    <>
      <SearchHeader isSearch={false} />
      <Card
        title="Logs"
        bordered={true}
        extra={
          <>
            <StyledExportButton
              onClick={() => {
                exportExcelFile(allLogs, 'logs');
              }}
            >
              <DownloadOutlined /> Export{' '}
            </StyledExportButton>
          </>
        }
      >
        <TableView
          ref={tableRef}
          columns={logColumns}
          dataSource={allLogs}
          pagination={{
            position: ['none', 'bottomRight'],
            total: allLogsListCount,
            showSizeChanger: false,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} Logs`,
            showQuickJumper: true,
            defaultPageSize: 50,
            ...pageConfig,
          }}
          scroll={{ x: 1000 }}
          onChange={onChange}
          loading={isLoading}
          bordered={true}
        />
      </Card>
    </>
  );
};

LogsListing.propTypes = {};

export default LogsListing;

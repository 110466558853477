import { createAsyncThunk } from '@reduxjs/toolkit';
import { contactDataAPIs } from 'services/api';
import axiosInstance from 'services/apiService';

export const getContactData = createAsyncThunk(
  'contactData/get',
  async (payload) => {
    const { data } = await axiosInstance({
      ...contactDataAPIs.get,
      params: payload,
    });

    return data;
  }
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { categoryAPIs } from 'services/api';
import axiosInstance from 'services/apiService';

export const addCategory = createAsyncThunk('category/add', async (payload) => {
  const { data } = await axiosInstance({
    ...categoryAPIs.add,
    data: payload,
  });
  return data;
});

export const updateCategory = createAsyncThunk(
  'category/update',
  async (payload) => {
    const api = categoryAPIs.update;

    const { data } = await axiosInstance({
      ...api,
      url: `category/${payload.id}/`,
      data: payload.formData,
    });
    return data;
  }
);

export const deleteCategory = createAsyncThunk(
  'admin/deleteCategory',
  async ({ categoryId }, { getState }) => {
    const api = categoryAPIs.delete;
    const { data } = await axiosInstance({
      ...api,
      url: `category/${categoryId}/`,
    });
    return data.message;
  }
);

export const getCategories = createAsyncThunk(
  'admin/getCategories',
  async (payload, { getState }) => {
    const { data } = await axiosInstance({
      ...categoryAPIs.get,
      params: payload,
    });
    return data;
  }
);

export const getAllCategories = createAsyncThunk(
  'admin/getAllCategories',
  async () => {
    const { data } = await axiosInstance({
      ...categoryAPIs.getAll,
    });
    return data?.categories;
  }
);

import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';

const Dashboard = ({ icon, title, number }) => {
  return (
    <Card bordered={true} style={{ padding: '15px 0px' }}>
      {icon}
      <h3
        style={{
          marginTop: '1.7rem',
          marginBottom: '0.6rem',
          lineHeight: '1px',
        }}
      >
        {title}
      </h3>
      <h3 style={{ color: '#969292', margin: '0px', marginTop: '6px' }}>
        {number}
      </h3>
    </Card>
  );
};

Dashboard.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  number: PropTypes.string,
};

export default Dashboard;

import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { message } from 'antd';

const initialiseFirebase = async () => {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };

  // Initialize Firebase
  const app = await initializeApp(firebaseConfig);
  // Initialize Firebase Authentication and get a reference to the service
  const auth = await getAuth(app);

  return auth;
};

export const googleSignIn = async () => {
  const auth = await initialiseFirebase();
  const provider = new GoogleAuthProvider();

  provider.setCustomParameters({
    prompt: 'select_account',
  });

  return signInWithPopup(auth, provider)
    .then((result) => {
      return result.user.getIdToken().then((idToken) => {
        return idToken;
      });
    })
    .catch((error) => {
      message.error(error.message);
    });
};

import styled from 'styled-components';
import { Menu } from 'antd';
import { Colors } from 'constant';

export const SidebarMenu = styled(Menu)`
  padding-top: 15px;
  .ant-menu-item {
    height: 30px;
    padding-left: 0px;
  }
`;

export const StyledWrapper = styled.div`
  background: ${Colors.THEMEBLUE};
  .ant-menu-light .ant-menu-item:hover,
  .ant-menu-light .ant-menu-item-active,
  .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-light .ant-menu-submenu-active,
  .ant-menu-item-selected,
  .ant-menu-light .ant-menu-submenu-title:hover {
    font-weight: 500;
  }
  .mobileVisible {
    display: none;
  }

  .mobileHidden {
    display: block;
  }
  .ant-menu {
    margin-left: 0rem;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: transparent;
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right: 0px;
  }
  .mobileButton {
    width: 2.6rem;
    margin: auto;
    display: block;
    margin-top: 1.5rem;
    margin-left: 0.5rem;
    height: 2.5rem;
    padding: 0px;
    background: ${Colors.NAVBLUE};
  }

  @media only screen and (max-width: 767px) {
    .mobileVisible {
      display: block;
    }
    h3 {
      margin-top: 9px;
    }
    .mobileHidden {
      display: none;
    }
  }
`;

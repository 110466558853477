/* eslint-disable max-lines */
import React from 'react';
import { Popconfirm, Space, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Colors } from 'constant';
import { Link } from 'react-router-dom';
import { StyledDeleteOutlined } from './listing.styles';

export const storeLocationTableColumn = (confirmDelete) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 60,
    align: 'center',
  },
  {
    title: 'Address',
    render: (row) => <>{row?.location?.address}</>,
    key: 'location.address',
    width: 60,
    align: 'center',
  },
  {
    title: 'Start Time',
    dataIndex: 'startTime',
    key: 'startTime',
    width: 60,
    // render: (row) => TimeFormat(row),
    align: 'center',
    // sorter: (a, b) => new Time(a.createdAt) - new Time(b.createdAt),
  },
  {
    title: 'End Time',
    dataIndex: 'endTime',
    key: 'endTime',
    width: 60,
    // render: (row) => TimeEventFormat(row),
    align: 'center',
    // sorter: (a, b) => new Time(a.createdAt) - new Time(b.createdAt),
  },
  {
    title: 'Action',
    key: 'action',
    width: 50,
    align: 'center',
    fixed: 'right',
    render: (storeLocation) => (
      <Space size="middle">
        <Popconfirm
          placement="topRight"
          title="Are you sure?"
          onConfirm={() => confirmDelete(storeLocation)}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title="Delete">
            <StyledDeleteOutlined />
          </Tooltip>
        </Popconfirm>
        <Link
          className="bold"
          state={{ storeLocation: storeLocation }}
          to="/dashboard/addStoreLocation"
        >
          <EditOutlined style={{ color: Colors.LIGHT_BLACK }} />
        </Link>
      </Space>
    ),
  },
];

export const dashboardAPIs = {
  salesSummary: {
    url: 'dashboard/allSummary',
    method: 'get',
    isAuthEnabled: true,
  },
  salesHistory: {
    url: 'dashboard/perYear',
    method: 'get',
    isAuthEnabled: true,
  },
};

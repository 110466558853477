import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'underscore';
import { getLogs } from 'redux/actions/logActions';

const LogsAdapter = createEntityAdapter({
  selectId: (log) => log._id,
});
const logsReducer = createSlice({
  name: 'logs',
  initialState: LogsAdapter.getInitialState({
    loading: true,
    error: null,
    allLogsListCount: 0,
    filters: {
      limit: 50,
      sortBy: '',
      searchKey: '',
    },
  }),
  reducers: {
    setFilters: (state, action) => {
      const filterUpdates = action.payload;
      Object.keys(filterUpdates).forEach((filterKey) => {
        if (!_.isEqual(state.filters[filterKey], action.payload[filterKey])) {
          if (filterKey !== 'skip' && filterKey !== 'current') {
            filterUpdates.current = 1;
            filterUpdates.skip = 0;
          }
          state.filters[filterKey] = action.payload[filterKey];
        }
      });
    },
  },
  extraReducers: {
    [getLogs.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getLogs.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.allLogsListCount = action.payload.count;
      LogsAdapter.upsertMany(state, action.payload.data);
      state.ids = action.payload.data.map((data) => data._id);
    },
    [getLogs.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});
export const selectLogsLoading = (state) => state.log.loading;
export const selectLogsCount = createSelector(
  [(state) => state.log.allLogsListCount],
  (count) => count
);
export const { selectAll: selectAllLogs, selectById: selectLogById } =
  LogsAdapter.getSelectors((state) => state.log);
export const { setFilters } = logsReducer.actions;
export default logsReducer.reducer;

import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'underscore';
import {
  getstoreLocation,
  deleteStoreLocation,
} from 'redux/actions/storeLocationActions';

const storeLocationsAdapter = createEntityAdapter({
  selectId: (storeLocation) => storeLocation._id,
});
const storeLocationsReducer = createSlice({
  name: 'storeLocation',
  initialState: storeLocationsAdapter.getInitialState({
    loading: true,
    error: null,
    allStoreLocationsListCount: 0,
    filters: {
      limit: 50,
      sortBy: '',
      searchKey: '',
    },
  }),
  reducers: {
    setFilters: (state, action) => {
      const filterUpdates = action.payload;
      Object.keys(filterUpdates).forEach((filterKey) => {
        if (!_.isEqual(state.filters[filterKey], action.payload[filterKey])) {
          if (filterKey !== 'skip' && filterKey !== 'current') {
            filterUpdates.current = 1;
            filterUpdates.skip = 0;
          }
          state.filters[filterKey] = action.payload[filterKey];
        }
      });
    },
  },
  extraReducers: {
    [getstoreLocation.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getstoreLocation.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.allStoreLocationsListCount = action.payload.count;
      storeLocationsAdapter.upsertMany(state, action.payload.storeLocations);
      state.ids = action.payload.storeLocations.map(
        (storeLocation) => storeLocation._id
      );
    },
    [getstoreLocation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [deleteStoreLocation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteStoreLocation.fulfilled]: (state, action) => {
      state.loading = false;
      storeLocationsAdapter.removeOne(state, action.meta.arg.id);
      state.allStoreLocationsListCount = action.payload.count - 1;
    },
    [deleteStoreLocation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});
export const selectStoreLocationLoading = (state) =>
  state.storeLocations.loading;
export const selectStoreLocationCount = createSelector(
  [(state) => state.storeLocations.allStoreLocationsListCount],
  (count) => count
);
export const {
  selectAll: selectAllStoreLocations,
  selectById: selectStoreLocationsById,
} = storeLocationsAdapter.getSelectors((state) => state.storeLocations);
export const { setFilters } = storeLocationsReducer.actions;
export default storeLocationsReducer.reducer;

import React from 'react';
import {
  IdcardOutlined,
  ShopOutlined,
  BellOutlined,
  ShoppingOutlined,
  SolutionOutlined,
  UserOutlined,
  UsergroupDeleteOutlined,
  TableOutlined,
  DashboardOutlined,
  AppstoreOutlined,
  NotificationOutlined,
  EnvironmentOutlined,
  FundViewOutlined,
} from '@ant-design/icons';

export const SidebarMenuItems = [
  { label: 'Dashboard', route: '/dashboard', icon: <DashboardOutlined /> },
  {
    label: 'Store Location',
    route: '/dashboard/storeLocation',
    icon: <EnvironmentOutlined />,
  },

  { label: 'Category', route: '/dashboard/category', icon: <IdcardOutlined /> },
  {
    label: 'Add Ons',
    route: '/dashboard/additionalCategory',
    icon: <AppstoreOutlined />,
  },
  {
    label: 'Banner',
    route: '/dashboard/banner',
    icon: <FundViewOutlined />,
  },

  { label: 'Product', route: '/dashboard/product', icon: <ShopOutlined /> },
  { label: 'Orders', route: '/dashboard/orders', icon: <ShoppingOutlined /> },
  {
    label: 'Notifications',
    route: '/dashboard/notifications',
    icon: <BellOutlined />,
  },
  {
    label: 'Customers',
    route: '/dashboard/customers',
    icon: <UsergroupDeleteOutlined />,
  },
  {
    label: 'AdminUser',
    route: '/dashboard/adminUser',
    icon: <UserOutlined />,
  },
  {
    label: 'Contact Data',
    route: '/dashboard/contactData',
    icon: <SolutionOutlined />,
  },
  {
    label: 'Logs',
    route: '/dashboard/logs',
    icon: <TableOutlined />,
  },
  {
    label: 'Events Discount',
    route: '/dashboard/eventsDiscount',
    icon: <NotificationOutlined />,
  },
];

import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'underscore';
import { getProducts, deleteProduct } from 'redux/actions/productActions';

const productAdapter = createEntityAdapter({
  selectId: (product) => product._id,
});
const productReducer = createSlice({
  name: 'product',
  initialState: productAdapter.getInitialState({
    loading: true,
    error: null,
    allProductsListCount: 0,
    filters: {
      limit: 50,
      sortBy: '',
      searchKey: '',
    },
  }),
  reducers: {
    setFilters: (state, action) => {
      const filterUpdates = action.payload;
      Object.keys(filterUpdates).forEach((filterKey) => {
        if (!_.isEqual(state.filters[filterKey], action.payload[filterKey])) {
          if (filterKey !== 'skip' && filterKey !== 'current') {
            filterUpdates.current = 1;
            filterUpdates.skip = 0;
          }
          state.filters[filterKey] = action.payload[filterKey];
        }
      });
    },
  },
  extraReducers: {
    [getProducts.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getProducts.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.allProductsListCount = action.payload.count;
      productAdapter.upsertMany(state, action.payload.products);
      state.ids = action.payload.products.map((product) => product._id);
    },
    [getProducts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [deleteProduct.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteProduct.fulfilled]: (state, action) => {
      state.loading = false;
      productAdapter.removeOne(state, action.meta.arg.productId);
      state.allProductsListCount = action.payload.count - 1;
    },
    [deleteProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});
export const selectProductLoading = (state) => state.product.loading;
export const selectProductCount = createSelector(
  [(state) => state.product.allProductsListCount],
  (count) => count
);
export const { selectAll: selectAllProducts, selectById: selectproductById } =
  productAdapter.getSelectors((state) => state.product);
export const { setFilters } = productReducer.actions;
export default productReducer.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { logAPIs } from 'services/api';
import axiosInstance from 'services/apiService';

export const getLogs = createAsyncThunk('request/getLogs', async (payload) => {
  const { data } = await axiosInstance({
    ...logAPIs.getLogs,
    params: payload,
  });
  return data;
});

import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'underscore';
import { getContactData } from 'redux/actions/contactDataActions';

const contactDataAdapter = createEntityAdapter({
  selectId: (contactData) => contactData._id,
});
const contactDataReducer = createSlice({
  name: 'contactData',
  initialState: contactDataAdapter.getInitialState({
    loading: true,
    error: null,
    allContactDataListCount: 0,
    filters: {
      limit: 50,
      sortBy: '',
      searchKey: '',
    },
  }),
  reducers: {
    setFilters: (state, action) => {
      const filterUpdates = action.payload;
      Object.keys(filterUpdates).forEach((filterKey) => {
        if (!_.isEqual(state.filters[filterKey], action.payload[filterKey])) {
          if (filterKey !== 'skip' && filterKey !== 'current') {
            filterUpdates.current = 1;
            filterUpdates.skip = 0;
          }
          state.filters[filterKey] = action.payload[filterKey];
        }
      });
    },
  },
  extraReducers: {
    [getContactData.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getContactData.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.allContactDataListCount = action.payload.count;
      contactDataAdapter.upsertMany(state, action.payload.contactData);
      state.ids = action.payload.contactData.map((data) => data._id);
    },
    [getContactData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});
export const selectContactDataLoading = (state) => state.contactData.loading;
export const selectContactDataCount = createSelector(
  [(state) => state.contactData.allContactDataListCount],
  (count) => count
);
export const {
  selectAll: selectAllContactData,
  selectById: selectContactDataById,
} = contactDataAdapter.getSelectors((state) => state.contactData);
export const { setFilters } = contactDataReducer.actions;
export default contactDataReducer.reducer;

import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Menu, Drawer, Button } from 'antd';
import { SidebarMenuItems } from './Sidebar.helper';
import { SidebarMenu, StyledWrapper } from './Sidebar.styled';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { find } from 'lodash-es';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { logout } from 'redux/reducers/authReducer';

const Sidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [currentSelection, setCurrentSelection] = useState(null);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const showDrawer = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  useEffect(() => {
    const finded = find(SidebarMenuItems, ['route', pathname])?.route || null;
    setCurrentSelection(finded);
  }, [pathname]);

  return (
    <StyledWrapper>
      <div className="mobileHidden">
        <SidebarMenu
          selectedKeys={[currentSelection]}
          mode="inline"
          theme="light"
        >
          {SidebarMenuItems.map((item) => (
            <Menu.Item
              key={item.route}
              icon={item.icon}
              onClick={() => navigate(item.route)}
              style={{ paddingLeft: '0px' }}
            >
              {item.label}
            </Menu.Item>
          ))}
          <Menu.Item
            key="LOGOUT"
            icon={<LogoutOutlined />}
            onClick={() => dispatch(logout())}
            style={{ paddingLeft: '0px' }}
          >
            Logout
          </Menu.Item>
        </SidebarMenu>
      </div>
      <div className="mobileVisible">
        <Button className="mobileButton" onClick={showDrawer}>
          <MenuUnfoldOutlined />
        </Button>
        <Drawer
          placement="left"
          closable={false}
          onClose={showDrawer}
          visible={visible}
          width={220}
          bodyStyle={{ padding: '10px 3px' }}
        >
          <Button type="primary" onClick={showDrawer}>
            <MenuFoldOutlined />
          </Button>
          <SidebarMenu
            selectedKeys={[currentSelection]}
            mode="inline"
            theme="light"
          >
            {SidebarMenuItems.map((item) => (
              <Menu.Item
                key={item.route}
                icon={item.icon}
                onClick={() => navigate(item.route)}
              >
                {item.label}
              </Menu.Item>
            ))}
          </SidebarMenu>
        </Drawer>
      </div>
    </StyledWrapper>
  );
};

Sidebar.propTypes = {
  children: PropTypes.node,
};

export default Sidebar;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

const Goback = ({ linkUrl }) => (
  <Link className="bold" to={linkUrl}>
    <h3 style={{ padding: '0.3rem' }}>
      <LeftOutlined /> Go back
    </h3>
  </Link>
);

Goback.propTypes = {
  linkUrl: PropTypes.string,
};

export default Goback;

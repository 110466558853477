import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { TableView } from 'components/StyledLayout/Layout.styled';
import { RedeemedEventProductsTableColumn } from './detail.helper';

const RedeemedEventProducts = (data) => {
  const RedeemedEventDiscountColumns = RedeemedEventProductsTableColumn();
  return (
    <>
      <Modal
        title="Redeemed Event Discount Products"
        visible={data?.visible}
        onCancel={() => data?.setVisible(false)}
        onOk={() => data?.setVisible(false)}
        width={800}
      >
        <div className="layout-styled">
          <TableView
            columns={RedeemedEventDiscountColumns}
            dataSource={data?.redeemedEventDiscount}
            bordered={false}
            pagination={false}
          />
        </div>
      </Modal>
    </>
  );
};

RedeemedEventProducts.propTypes = {
  data: PropTypes?.any,
};

export default RedeemedEventProducts;

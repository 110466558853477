import styled from 'styled-components';
import { Layout } from 'antd';
import { heightOfNavbar, Colors } from 'constant';

const { Sider, Header, Content } = Layout;
export const StyledLayout = styled(Layout)`
  background: ${Colors.THEMEBLUE};
`;
export const StyledMainLayout = styled(Layout)`
  background: ${Colors.THEMEBLUE};
  .mainLayout {
    padding: 0px 6rem;
  }
  .ant-drawer-body {
    padding: 0px;
  }
  @media only screen and (max-width: 767px) {
    .mainLayout {
      padding: 0px 0.7rem;
    }
  }
`;

export const StyledSider = styled(Sider)`
  width: 4rem;
  background: ${Colors.THEMEBLUE};
  height: 100vh;
  ul {
    background: ${Colors.THEMEBLUE};
  }
`;

export const MobileStyledSider = styled(Sider)`
  width: 2rem;
  background: ${Colors.THEMEBLUE};
  height: 100vh;
  ul {
    background: ${Colors.THEMEBLUE};
  }
`;

export const StyledHeader = styled(Header)`
  height: ${heightOfNavbar};
  background: ${Colors.THEMEBLUE};
  position: fixed;
  width: 100%;
  z-index: 100;
`;

export const StyledContent = styled(Content)`
  background: ${Colors.THEMEBLUE};
  a > h3 {
    color: #1b5dbf;
    :hover {
      text-decoration: underline;
    }
  }
  padding: 7px;
  .ant-card {
    border-radius: 12px;
    box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
  }
  .ant-card-body {
    padding: 0px;
  }
  .ant-card-head {
    border-bottom: 0px;
  }
`;

export const StyledWrapper = styled.div`
  background: ${Colors.THEMEBLUE};

  .mobileVisible {
    display: none;
  }

  .mobileHidden {
    display: block;
  }
  .ant-table-pagination {
    justify-content: right;
  }

  .ant-layout-sider {
    max-width: 170px !important;
    min-width: 170px !important;
    width: 170px !important;
  }

  @media only screen and (max-width: 767px) {
    .mobileVisible {
      display: block;
    }

    .mobileVisible .ant-layout-sider {
      max-width: 50px !important;
      min-width: 50px !important;
      width: 50px !important;
      background: ${Colors.THEMEBLUE} !important;
    }

    .mobileHidden {
      display: none;
    }
  }
`;

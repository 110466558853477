/* eslint-disable max-lines */
import React from 'react';
import { Popconfirm, Space, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { CategoryIcon, StyledDeleteOutlined } from './listing.styles';
import { dateFormat } from 'configs';
import { Colors } from 'constant';
import axios from 'axios';
const ExcelJS = require('exceljs');

export const categoryTableColumn = (confirmDelete) => [
  {
    title: 'Icon',
    key: 'icon',

    render: (category) => (
      <CategoryIcon
        shape="square"
        size={60}
        src={category?.icon}
        style={{ backgroundColor: category?.color }}
      ></CategoryIcon>
    ),
    width: 40,
    align: 'center',
  },
  {
    title: 'Name',
    render: (category) => <>{category?.name?.en}</>,
    width: 70,
    key: 'name.en',
    align: 'center',
    sorter: (a, b) => a.name?.en.length - b.name?.en.length,
  },
  // {
  //   title: 'Arabic name',
  //   render: (category) => <>{category?.name?.ar}</>,
  //   width: 70,
  //   key: 'name.ar',
  //   align: 'center',
  // },
  {
    title: 'Color',
    dataIndex: 'color',
    key: 'color',
    width: 70,
    align: 'center',
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 80,
    render: (row) => dateFormat(row),
    align: 'center',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 50,
    align: 'center',
  },
  {
    title: 'Action',
    key: 'action',
    width: 50,
    align: 'center',
    fixed: 'right',
    render: (category) => (
      <Space size="middle">
        <Popconfirm
          placement="topRight"
          title="Are you sure! delete this category?"
          onConfirm={() => confirmDelete(category)}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title="Delete">
            <StyledDeleteOutlined />
          </Tooltip>
        </Popconfirm>
        <Link
          className="bold"
          state={{ category: category }}
          to="/dashboard/addCategory"
        >
          <EditOutlined style={{ color: Colors.LIGHT_BLACK }} />
        </Link>
      </Space>
    ),
  },
];

export const getSortType = (sorter) => {
  if (!sorter.order) {
    return null;
  }
  switch (sorter.columnKey) {
    case 'createdAt':
      return sorter.order === 'ascend' ? 'firstCreated' : 'lastCreated';
    default:
      return null;
  }
};

const toDataURL = async (url) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .get(url, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'image/png' });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

export const exportExcelFile = (data, name) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('My Sheet');
  sheet.properties.defaultRowHeight = 50;
  sheet.properties.defaultCellStyle = {};
  //sheet.properties.defaultCellStyle.alignment = { horizontal: 'left' };

  sheet.getRow(1).font = {
    name: 'roboto',
    family: 4,
    size: 16,
    bold: true,
  };
  // const firstRow = sheet.getRow(1);
  // firstRow.height = 100;
  sheet.columns = [
    {
      header: 'Icon',
      key: 'icon',
      width: 20,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Name',
      key: 'name',
      width: 20,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Color',
      key: 'color',
      width: 20,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Created Date',
      key: 'date',
      width: 30,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Status',
      key: 'status',
      width: 20,
      style: {
        font: {
          size: 12,
        },
      },
    },
  ];

  const promise = Promise.all(
    data.map(async (item, index) => {
      const rowNumber = index + 1;
      sheet.addRow({
        //icon: item?.icon,
        name: item?.name?.en,
        color: item?.color,
        date: item?.createdAt,
        status: item?.status || 'enabled',
      });

      const result = await toDataURL(item?.icon);
      const splitted = item?.icon.split('.');
      const extName = splitted[splitted.length - 1];

      const imageId2 = workbook.addImage({
        base64: result,
        extension: extName,
      });

      sheet.addImage(imageId2, {
        tl: { col: 0, row: rowNumber },
        ext: { width: 50, height: 50 },
      });

      return null;
    })
  );

  // Iterate over all rows in the worksheet
  sheet.eachRow((row) => {
    // Iterate over all cells in the row
    row.eachCell((cell) => {
      // Set the alignment properties for the cell
      cell.alignment = {
        horizontal: 'center',
        vertical: 'middle',
      };
    });
  });

  promise.then(() => {
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = name?.toString() || 'download.xlsx';
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  });
};

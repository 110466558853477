export const authAPIs = {
  login: {
    url: 'admin/auth/login',
    method: 'post',
    isAuthEnabled: false,
  },
  loginWithGoogle: {
    url: 'admin/auth/google-login',
    method: 'post',
    isAuthEnabled: false,
  },
};

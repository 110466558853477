import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'underscore';
import {
  getNotifications,
  deleteNotification,
} from 'redux/actions/notificationActions';

const notificationAdapter = createEntityAdapter({
  selectId: (notification) => notification._id,
});
const notificationReducer = createSlice({
  name: 'notification',
  initialState: notificationAdapter.getInitialState({
    loading: true,
    error: null,
    allNotificationsListCount: 0,
    filters: {
      limit: 50,
      sortBy: '',
      searchKey: '',
    },
  }),
  reducers: {
    setFilters: (state, action) => {
      const filterUpdates = action.payload;
      Object.keys(filterUpdates).forEach((filterKey) => {
        if (!_.isEqual(state.filters[filterKey], action.payload[filterKey])) {
          if (filterKey !== 'skip' && filterKey !== 'current') {
            filterUpdates.current = 1;
            filterUpdates.skip = 0;
          }
          state.filters[filterKey] = action.payload[filterKey];
        }
      });
    },
  },
  extraReducers: {
    [getNotifications.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.allNotificationsListCount = action.payload.count;
      notificationAdapter.upsertMany(state, action.payload.notifications);
      state.ids = action.payload.notifications.map(
        (notification) => notification._id
      );
    },
    [getNotifications.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [deleteNotification.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteNotification.fulfilled]: (state, action) => {
      state.loading = false;
      notificationAdapter.removeOne(state, action.meta.arg.id);
      state.allNotificationsListCount = action.payload.count - 1;
    },
    [deleteNotification.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});
export const selectNotificationLoading = (state) => state.notification.loading;
export const selectNotificationCount = createSelector(
  [(state) => state.notification.allNotificationsListCount],
  (count) => count
);
export const {
  selectAll: selectAllNotifications,
  selectById: selectNotificationById,
} = notificationAdapter.getSelectors((state) => state.notification);
export const { setFilters } = notificationReducer.actions;
export default notificationReducer.reducer;

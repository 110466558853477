import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { ItemsTableColumn } from './detail.helper';
import { StyledWrapper } from './Detail.styled';
import { TableView } from '../../../components/StyledLayout/Layout.styled';

const ReceiptData = ({ orderDetails }) => {
  const cupsHistory = orderDetails?.cupsHistory;
  const redeemedProduct = cupsHistory?.redeemedProduct;
  const cupsDiscount = redeemedProduct?.price;
  const pointsHistory = orderDetails?.pointsHistory;
  const pointsDiscount = pointsHistory?.redeemedPointsInCurrency;
  const subtotal = orderDetails?.subtotal;
  const discountTotal = orderDetails?.discountTotal?.toFixed(2);
  const total = orderDetails?.total?.toFixed(2);

  const redeemedBirthdayReward = orderDetails?.redeemedBirthdayReward;
  const birthdayRedeemedProduct = redeemedBirthdayReward?.redeemedProduct;
  const birthdayDiscount = birthdayRedeemedProduct?.discountedPrice
    ? birthdayRedeemedProduct?.discountedPrice
    : birthdayRedeemedProduct?.price;

  const redeemedEvent = orderDetails?.redeemedEventDiscount;
  const eventDiscount = redeemedEvent?.discountTotal;
  const receiptData = orderDetails && [
    { label: 'Subtotal', price: subtotal },
    ...(eventDiscount
      ? [{ label: 'Event Discount', price: eventDiscount }]
      : []),
    ...(birthdayDiscount
      ? [{ label: 'Birthday Discount', price: birthdayDiscount }]
      : []),
    ...(cupsDiscount ? [{ label: 'Cups Discount', price: cupsDiscount }] : []),
    ...(pointsDiscount
      ? [{ label: 'Points Discount', price: pointsDiscount }]
      : []),
    { label: 'Discount Total', price: discountTotal },
    {
      label: 'Total',
      price: total,
      prevPrice: discountTotal > 0 && subtotal,
      isTotal: true,
    },
  ];
  const itemsColumns = ItemsTableColumn();
  return (
    <StyledWrapper>
      <div className="layout-styled" style={{ marginBottom: '0px' }}>
        <TableView
          columns={itemsColumns}
          dataSource={orderDetails?.items}
          bordered={false}
          pagination={false}
        />
        <div style={{ textAlign: 'end' }}>
          {receiptData.map((item, index) => (
            <>
              {item.isTotal && (
                <>
                  <Divider
                    style={{
                      marginTop: '6px',
                      marginBottom: '0px',
                      paddingBottom: '0px',
                    }}
                  />
                </>
              )}
              <p
                style={{
                  marginTop: item?.isTotal ? '0.6rem' : '0.4rem',
                  marginBottom: '0px',
                }}
              >
                <b
                  style={{
                    marginRight: '4rem',
                  }}
                >
                  {item.label}
                </b>
                <span
                  style={{ marginLeft: '10px', float: 'right' }}
                >{`$${parseFloat(item.price).toFixed(2)}`}</span>
                <span>
                  {item.prevPrice && (
                    <span
                      style={{
                        marginLeft: '10px',
                        textDecorationLine: 'line-through',
                        float: 'right',
                      }}
                    >
                      {item.prevPrice &&
                        `$${parseFloat(item.prevPrice).toFixed(2)}`}
                    </span>
                  )}
                </span>
              </p>
            </>
          ))}
        </div>
      </div>
    </StyledWrapper>
  );
};
ReceiptData.propTypes = {
  orderDetails: PropTypes.object,
};

export default ReceiptData;

import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router';
import Sidebar from 'components/Sidebar';
import { useSelector } from 'react-redux';
import {
  StyledContent,
  StyledLayout,
  StyledSider,
  MobileStyledSider,
  StyledWrapper,
  StyledMainLayout,
} from './layouts.styled';
import Logo from 'components/Logo';

const Layout = () => {
  const authData = useSelector((store) => store.auth?.user);
  return (
    <StyledMainLayout>
      <StyledLayout className="mainLayout">
        <StyledWrapper>
          <div className="mobileHidden">
            <StyledSider theme="light">
              <Logo />
              <h4>{authData?.email}</h4>
              <Sidebar />
            </StyledSider>
          </div>
          <div className="mobileVisible">
            <MobileStyledSider theme="light">
              <Sidebar />
            </MobileStyledSider>
          </div>
        </StyledWrapper>
        <StyledLayout>
          <StyledContent>
            <Outlet />
          </StyledContent>
        </StyledLayout>
      </StyledLayout>
    </StyledMainLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;

import styled from 'styled-components';
import { Input, Table } from 'antd';
import { Colors } from 'constant';

export const StyledLayout = styled.div`
  padding: 10px 25px;
  background: ${Colors.WHITE};
  border-radius: 7px;
  margin-bottom: 20px;
  box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
  .ant-form-item {
    margin-bottom: 14px;
  }
  .formView {
    margin-top: 0.8rem;
  }
  .ant-btn {
    margin-right: 1.1rem;
    padding: 0px 20px;
  }
  .ant-input .ant-input-number {
    width: 100%;
    padding: 5px 10px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-sizing: border-box;
  }
  .ant-form-item-label {
    padding: 0px;
  }
`;

export const SearchField = styled(Input)`
  border-radius: 18px;
`;

export const StyledExportButton = styled.button`
  padding: 3px 12px;
  cursor: pointer;
  margin-left: 6px;
  border: 1px solid ${Colors.GREY};
  background-color: transparent;
  color: ${Colors.BLACK};
  font-size: 1.3em;
  box-shadow: 0 6px 6px rgb(0 0 0 / 1%);
  :hover {
    background-color: #f8f8f8;
    box-shadow: 0px 10px 20px rgb(153 158 156 / 20%);
  }
`;

export const TableView = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: ${Colors.WHITE};
    border-bottom: 1px solid ${Colors.BORDER_GREY};
  }
  .ant-table-pagination.ant-pagination {
    margin: 16px;
  }
  .ant-table table {
    border-radius: 0px;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tfoot
    > tr
    > td {
    border-right: 0px;
  }
  .ant-table-thead > tr > th {
    background-color: ${Colors.THEMEBLUE};
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 10px 14px;
  }
  .ant-table-column-sorters {
    display: inline-block;
  }
`;

export const StyledWrapper = styled.div`
  .layout-styled {
    padding: 15px;
    background: ${Colors.WHITE};
    box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
    border-radius: 12px;
    margin-bottom: 20px;
  }
  .link {
    color: ${Colors.LIGHT_BLACK};
    text-decoration: underline;
  }

  .lightBlack {
    color: ${Colors.LIGHT_BLACK};
  }
  .card-style {
    margin: 20px 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02),
      0 6px 20px 0 rgba(0, 0, 0, 0.05);
  }

  .statusStyle {
    font-weight: 600;
    margin-left: 5px;
  }

  .NoMargin {
    margin-bottom: 0.3rem;
  }
  .ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 20%;
    min-width: 20%;
    float: right;
  }
`;

import styled from 'styled-components';
import { Colors } from 'constant';

export const StyledWrapper = styled.div`
  border-collapse: collapse;
  height: 100vh;
  display: flex;
  background-color: ${Colors.THEMEBLUE};
  input {
    height: 30px;
    color: #000000;
    padding: 20px 12px;
    min-width: 300px;
  }
  .card-style {
    border-radius: 16px;
    padding: 16px 16px;
    width: 400px;
    margin: auto;
  }
  .ant-row {
    margin-bottom: 0px;
  }
  .input-password {
    padding: 5px 12px;
  }
  .top-left-radius {
    border-top-left-radius: 8px;
  }
  .top-right-radius {
    border-top-right-radius: 8px;
  }
  .bottom-left-radius {
    border-bottom-left-radius: 8px;
  }
  .bottom-right-radius {
    border-bottom-right-radius: 8px;
  }
  .margin-top-12 {
    margin-top: 12px;
  }
  .text-bold {
    font-weight: bold;
  }
`;

export const SidebarLogo = styled.img`
  width: 6rem;
  margin: auto;
  display: block;
  margin-top: 0.5rem;
`;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TableView } from '../../../components/StyledLayout/Layout.styled';
import SearchHeader from '../../../components/SearchHeader';
import { BannerTableColumn } from './listing.helper';
import { Card, message } from 'antd';
import { AddButton, AddIcon } from 'modules/requests/requests.styles';

import {} from 'redux/reducers/bannerReducer';
import {
  getBanners,
  deleteBanner,
  updateBanner,
} from 'redux/actions/bannerActions';

function Listing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onBannerDelete = async (banner) => {
    try {
      const body = {
        bannerId: banner._id,
      };
      await dispatch(deleteBanner(body));
      message.success('Banner deleted successfully');
    } catch (err) {
      message.error('Error while deleting Banner');
    }
  };

  const onOrderChange = async (banner, action) => {
    try {
      const formData = new FormData();
      formData.append('title', banner.title);
      if (action === 'up') {
        formData.append('order', banner.order - 1);
        dispatch(updateBanner({ id: banner._id, formData }));
      } else {
        formData.append('order', banner.order + 1);
        dispatch(updateBanner({ id: banner._id, formData }));
      }
    } catch (error) {
      message.error('Error while update Banner order');
    }
  };

  const banners = useSelector((state) => state.banner);
  const bannerColumns = BannerTableColumn(onBannerDelete, onOrderChange);

  useEffect(() => {
    dispatch(getBanners());
  }, [dispatch]);
  return (
    <>
      <SearchHeader isSearch={false} />
      <Card
        title="Banners"
        bordered={true}
        extra={
          <>
            <AddButton
              onClick={() => navigate('/dashboard/addBanner')}
              className="space-align-block"
            >
              <AddIcon />
              New
            </AddButton>
          </>
        }
      >
        <TableView
          columns={bannerColumns}
          dataSource={banners.allBanners}
          pagination={{
            position: ['none', 'bottomRight'],
            total: `${banners.allBannerListCount}`,
          }}
          bordered={true}
          loading={banners.loading}
        />
      </Card>
    </>
  );
}

export default Listing;

import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { InputNumber, Button, Select, Input, Row, Col } from 'antd';
const { Option } = Select;

const sizes = [
  {
    label: '8oz',
    value: '8oz',
  },
  {
    label: '12oz',
    value: '12oz',
  },
  { label: '16oz', value: '16oz' },
  { label: '20oz', value: '20oz' },
  { label: '32oz', value: '32oz' },
];

export const addSizes = (Form) => {
  return (
    <Form.List name="sizes">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field) => (
            <Row key={field.key} gutter={16}>
              <Col span={11}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.price !== curValues.price ||
                    prevValues.size !== curValues.size
                  }
                >
                  {() => (
                    <Form.Item
                      {...field}
                      label="Size"
                      name={[field.name, 'size']}
                      rules={[{ required: true, message: 'Missing size' }]}
                    >
                      <Select style={{ width: '100%' }}>
                        {sizes.map((item) => (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>{' '}
              <Col span={11}>
                <Form.Item
                  {...field}
                  label="Price"
                  name={[field.name, 'price']}
                  rules={[{ required: true, message: 'Missing price' }]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={2}>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Col>
            </Row>
          ))}

          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Add size
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export const categoryFields = (Form) => {
  return (
    <Form.List name="categories">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field) => (
            <>
              <Row gutter={16} key={field.key}>
                <Col span={11}>
                  <Form.Item
                    {...field}
                    label="Category name in english"
                    name={[field.name, 'en']}
                    rules={[{ required: true, message: 'Missing category' }]}
                  >
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    {...field}
                    label="Category name in arabic"
                    name={[field.name, 'ar']}
                    rules={[{ required: true, message: 'Missing category' }]}
                  >
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Col>
              </Row>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.category !== curValues.category ||
                  prevValues.items !== curValues.items
                }
              >
                <Form.List name={[field.fieldKey, 'items']}>
                  {(items, { add, remove }) => (
                    <>
                      {items.map((field) => (
                        <Row gutter={16} key={field.key}>
                          <Col span={7}>
                            <Form.Item
                              {...field}
                              label="Name in english"
                              name={[field.name, 'item.en']}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={7}>
                            <Form.Item
                              {...field}
                              label="Name in arabic"
                              name={[field.name, 'item.ar']}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...field}
                              label="price"
                              name={[field.name, 'item.price']}
                              rules={[{ required: true }]}
                            >
                              <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            <MinusCircleOutlined
                              onClick={() => remove(field.name)}
                            />
                          </Col>
                        </Row>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add field
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </>
          ))}

          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Additional Category
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export const formatedProduct = (product, locations) => {
  const productCategories = [];
  const additionCategory = [];

  if (product?.categories?.length > 0) {
    product?.categories?.map(async (category) => {
      productCategories.push({
        en: category?.category?.en,
        ar: category?.category?.ar,
        items: category?.items?.map((itemObj) => ({
          'item.en': itemObj?.item?.en,
          'item.ar': itemObj?.item?.ar,
          'item.price': itemObj?.price,
        })),
      });
    });
  }
  const locationsDefault = [];
  if (locations.length > 0) {
    locations?.map(async (location) => {
      locationsDefault.push(location?.value);
    });
  }

  if (product?.categoryAdditions?.length > 0) {
    product?.categoryAdditions?.map(async (category) => {
      additionCategory.push(category?._id);
    });
  }

  return {
    'name.en': product?.name?.en,
    'name.ar': product?.name?.ar,
    'description.en': product?.description?.en,
    'description.ar': product?.description?.ar,
    category: product?.category?._id,
    // additionalCategory: product?.additionalCategories,
    price: product?.price,
    discountedPrice: product?.discountedPrice,
    sizes: product?.sizes,
    image: product?.image,
    color: product?.color,
    categories: productCategories,
    additionalCategory: additionCategory,
    status: product?.status,
    storeLocation: product?.storeLocation || locationsDefault,
  };
};

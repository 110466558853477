import React from 'react';

export const HistoryTableColumn = () => [
  {
    title: 'Year',
    dataIndex: '_id',
    key: '_id',
    width: 60,
    align: 'center',
  },
  {
    title: 'Number of customers',
    render: (item) => <>{item?.users || 0}</>,
    key: 'users',
    width: 60,
    align: 'center',
  },
  {
    title: 'Number of orders',
    render: (item) => <>{item?.orders || 0}</>,
    key: 'orders',
    width: 60,
    align: 'center',
  },
  {
    title: 'Amount of sales',
    render: (item) => <>${item?.sum_val || 0}</>,
    key: 'sum_val',
    width: 60,
    align: 'center',
  },
];

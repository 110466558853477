import moment from 'moment';

export const BASE_URL = process.env.REACT_APP_API_URL;

export const HTTP_METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

// Style configs
export const EN_FONT =
  '"Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
// export const AR_FONT = '"Noto Sans Arabic", sans-serif';

export const dateFormat = (date = null) =>
  date ? moment(date).format('DD.MM.YYYY HH:mm a') : null;

export const dateEventFormat = (date = null) => {
  if (date) {
    date = new Date(date);
    date.setDate(date.getDate() - 1);
    return moment(date).format('DD.MM.YYYY');
  }
  return null;
};

export const requestOptions = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  APPROVED: 'approved',
};

const roles = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
};

export const rolesOptions = Object.keys(roles).map((key) => ({
  label: key,
  value: roles[key],
}));

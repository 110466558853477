import { createSlice } from '@reduxjs/toolkit';
import { login, loginWithGoogle } from 'redux/actions/authActions';

const authReducer = createSlice({
  name: 'auth',
  initialState: { loginLoading: false, authToken: null, user: null },
  reducers: {
    logout: (state) => {
      state.authToken = null;
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loginLoading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.authToken = action?.payload?.token;
      state.user = action?.payload?.user;
    },
    [login.rejected]: (state) => {
      state.loginLoading = false;
    },
    [loginWithGoogle.fulfilled]: (state, action) => {
      state.authToken = action?.payload?.token;
      state.user = action?.payload?.user;
    },
  },
});

export const { logout } = authReducer.actions;

export default authReducer.reducer;

import { Avatar } from 'antd';
import { Colors } from 'constant';
import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';

export const CategoryIcon = styled(Avatar)`
  border: 1px solid ${Colors.BORDER_GREY};
  margin-right: 1rem;
`;

export const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${Colors.LIGHT_BLACK};
`;

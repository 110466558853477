import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { getCustomerDetail } from 'redux/actions/usersActions';
import { StyledWrapper } from './Detail.styled';
import GoBack from '../../../components/GoBack';
import DetailInfo from './DetailInfo';
import EmailInfo from './EmailInfo';
import RewardsHistory from './RewardsHistory';
import CupsHistory from './CupsHistory';
import BirthdayRewards from './BirthdayRewards';
import EventDiscount from './EventDiscount';

const Detail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { userId } = location?.state || {};
  const [userDetail, setUserDetail] = useState();
  const getUserDetail = useCallback(async () => {
    try {
      const { user } = await dispatch(
        getCustomerDetail({ id: userId })
      ).unwrap();
      setUserDetail(user?.[0] || {});
    } catch (err) {
      message.error('Something went wrong');
    }
  }, [dispatch, userId]);

  useEffect(() => {
    getUserDetail();
  }, [dispatch, userId, getUserDetail]);

  return (
    <StyledWrapper>
      <GoBack linkUrl={'/dashboard/customers'} />
      <EmailInfo customer={userDetail} />
      <DetailInfo customer={userDetail} />
      <RewardsHistory customer={userDetail} />
      <CupsHistory customer={userDetail} />
      <BirthdayRewards customer={userDetail} />
      <EventDiscount customer={userDetail} />
    </StyledWrapper>
  );
};
Detail.propTypes = {};

export default Detail;

import React from 'react';
import PropTypes from 'prop-types';
import { TableView } from 'components/StyledLayout/Layout.styled';
import { StyledWrapper } from './Detail.styled';
import { CupsTableColumn } from './detail.helper';

const RedeemedBirthdayRewards = ({ customer }) => {
  const birthdayRewardsColumns = CupsTableColumn();

  return (
    <StyledWrapper>
      <div className="layout-styled">
        <h2>Redeemed Birthday Rewards:</h2>
        <TableView
          columns={birthdayRewardsColumns}
          dataSource={customer?.birthdayHistory}
          bordered={false}
          pagination={false}
        />
      </div>
    </StyledWrapper>
  );
};
RedeemedBirthdayRewards.propTypes = {
  customer: PropTypes.object,
};

export default RedeemedBirthdayRewards;

export const bannerAPISs = {
  add: {
    url: 'banner',
    method: 'post',
    isAuthEnabled: true,
  },
  get: {
    url: 'banner',
    method: 'get',
    isAuthEnabled: true,
  },
  delete: {
    url: 'banner',
    method: 'delete',
    isAuthEnabled: true,
  },
  update: {
    url: 'banner',
    method: 'patch',
    isAuthEnabled: true,
  },
};

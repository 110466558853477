/* eslint-disable max-lines */
import React from 'react';
import { Popconfirm, Space, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Colors } from 'constant';
import { Link } from 'react-router-dom';
import { StyledDeleteOutlined } from './listing.styles';
import { dateFormat, dateEventFormat } from 'configs';

export const eventDiscountTableColumn = (confirmDelete) => [
  {
    title: 'Name',
    render: (event) => <>{event?.name?.en}</>,
    width: 70,
    key: 'name',
    align: 'center',
  },
  {
    title: 'Discount Categories',
    render: (event) => (
      <>
        {' '}
        {event?.categoriesList.map(function (item) {
          return <>{item?.name?.en},</>;
        })}
      </>
    ),
    width: 90,
    key: 'discountedCategories',
    align: 'center',
  },
  {
    title: 'Discount Percentage',
    render: (event) => (
      <>{parseFloat(event?.discountPercentage * 100).toFixed(2)}</>
    ),
    width: 70,
    key: 'discountPercentage',
    align: 'center',
  },
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
    width: 60,
    render: (row) => dateFormat(row),
    align: 'center',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
    width: 60,
    render: (row) => dateEventFormat(row),
    align: 'center',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
  },
  {
    title: 'Action',
    key: 'action',
    width: 50,
    align: 'center',
    fixed: 'right',
    render: (eventDiscount) => (
      <Space size="middle">
        <Popconfirm
          placement="topRight"
          title="Are you sure! delete this event discount customer already received the notification?"
          onConfirm={() => confirmDelete(eventDiscount)}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title="Delete">
            <StyledDeleteOutlined />
          </Tooltip>
        </Popconfirm>
        <Link
          className="bold"
          state={{ eventDiscounts: eventDiscount }}
          to="/dashboard/addEventsDiscount"
        >
          <EditOutlined style={{ color: Colors.LIGHT_BLACK }} />
        </Link>
      </Space>
    ),
  },
];

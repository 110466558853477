import { createSlice } from '@reduxjs/toolkit';

import {
  getBanners,
  deleteBanner,
  updateBanner,
} from 'redux/actions/bannerActions';

const initialState = {
  allBanners: [],
  loading: true,
  error: null,
  allBannerListCount: 0,
};

const bannerReducer = createSlice({
  name: 'banner',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBanners.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBanners.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.allProductsListCount = action.payload.count;
        state.allBanners = action.payload.banners;
      })
      .addCase(getBanners.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteBanner.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBanner.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.allProductsListCount = action.payload.count;
        state.allBanners = action.payload.banners;
      })
      .addCase(deleteBanner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateBanner.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBanner.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.allProductsListCount = action.payload.count;
        state.allBanners = action.payload.banners;
      })
      .addCase(updateBanner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default bannerReducer.reducer;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, message, Select, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { rolesOptions } from 'constant/Options';
import { inputFormItems } from './invite.helper';
import { addAdmin, getAllAdmins } from 'redux/actions/adminActions';
import { StyledLayout } from '../../../components/StyledLayout/Layout.styled';
import GoBack from '../../../components/GoBack';

const { Title } = Typography;

const AddAdminModal = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleFinish = async (values) => {
    try {
      setIsLoading(true);
      await dispatch(addAdmin(values)).unwrap();
      await dispatch(getAllAdmins({})).unwrap();
      setIsLoading(false);
      message.success('Added the admin successfully');
      form.resetFields();
      setIsLoading(false);
      navigate('/dashboard/adminUser');
    } catch (e) {
      setIsLoading(false);
      message.error('Something went wrong');
    }
  };
  return (
    <>
      <GoBack linkUrl="/dashboard/adminUser" />
      <StyledLayout>
        <Title level={4}>Create admin user</Title>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          className="formView"
        >
          {inputFormItems.map((item, index) => (
            <Form.Item label={item.label} name={item.name} key={index}>
              <Input />
            </Form.Item>
          ))}
          <Form.Item label="Role" name="role">
            <Select options={rolesOptions}></Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="button"
              loading={isLoading}
            >
              Submit
            </Button>
            <Button
              type="secondary"
              htmlType="button"
              className="cancelButton"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </StyledLayout>
    </>
  );
};

AddAdminModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default AddAdminModal;

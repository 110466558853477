import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { message, Card } from 'antd';
import { AddButton, AddIcon } from 'modules/requests/requests.styles';
import { usersTableColumn } from './helper';
import { getSkipCount } from 'utils';
import { TableView } from '../../../components/StyledLayout/Layout.styled';
import SearchHeader from '../../../components/SearchHeader';
import {
  deleteAdmin,
  updateAdminStatus,
  getAllAdmins,
} from 'redux/actions/adminActions';

const AdminTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const initialPageConfig = {
    current: 1,
    pageSize: 10,
    limit: 10,
    skip: getSkipCount(1, 10),
  };
  const [totalCount, setTotalCount] = useState(0);

  const [pageConfig, setPageConfig] = useState(initialPageConfig);

  const onChange = (pagination, filters) => {
    const newPageConfig = {
      current: pagination.current,
      pageSize: pagination.pageSize,
      limit: pagination.pageSize,
      skip: getSkipCount(pagination.current, pagination.pageSize),
      status: filters?.status?.[0] || null,
      searchKey: pageConfig?.searchKey,
    };
    setPageConfig(newPageConfig);
  };

  const onUserInactive = async (user) => {
    try {
      const body = {
        _id: user._id,
        status: 'INACTIVE',
      };
      await dispatch(updateAdminStatus(body)).unwrap();
      message.success('User blocked successfully');
      getAdmins();
    } catch (err) {
      message.error('Something went wrong');
    }
  };

  const onUserDelete = async (user) => {
    try {
      const body = {
        _id: user._id,
      };
      await dispatch(deleteAdmin(body)).unwrap();
      message.success('User deleted successfully');
      getAdmins();
    } catch (err) {
      message.error('Error while deleting user');
    }
  };

  const onUserActivate = async (user) => {
    try {
      const body = {
        _id: user._id,
        status: 'ACTIVE',
      };
      await dispatch((dispatch) => dispatch(updateAdminStatus(body))).unwrap();
      message.success('User Activated successfully');
      getAdmins();
    } catch (err) {
      message.error('Error while activating user');
    }
  };

  const getAdmins = useCallback(async () => {
    try {
      setLoading(true);
      const { users, count } = await dispatch(
        getAllAdmins(pageConfig)
      ).unwrap();
      setAdmins(users);
      setTotalCount(count);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.error('Something went wrong');
    }
  }, [dispatch, pageConfig]);

  useEffect(() => {
    getAdmins();
  }, [dispatch, pageConfig, getAdmins]);

  const usersColumns = usersTableColumn(
    onUserInactive,
    onUserDelete,
    onUserActivate
  );

  return (
    <>
      <SearchHeader isSearch={false} />
      <Card
        title="Admin Users"
        bordered={true}
        extra={
          <AddButton onClick={() => navigate('/dashboard/addAdminUser')}>
            <AddIcon style={{ marginRight: '4px' }} />
            New
          </AddButton>
        }
      >
        <TableView
          columns={usersColumns}
          dataSource={admins}
          pagination={{
            position: ['none', 'bottomRight'],
            total: totalCount,
            showSizeChanger: false,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} AdminUsers`,
            showQuickJumper: true,
            defaultPageSize: 50,
            ...pageConfig,
          }}
          scroll={{ x: 1000 }}
          onChange={onChange}
          loading={loading}
          bordered={true}
        />
      </Card>
    </>
  );
};

export default AdminTable;

import moment from 'moment';

export const formatedStorelocation = (store) => {
  const defaultTime = moment().format('HH:mm a');
  const time = moment(defaultTime, 'HH:mm a');
  return {
    name: store?.name,
    address: store?.location?.address,
    location: store?.location,
    coords: store?.location?.coords?.coordinates,
    startTime: store?.startTime ? moment(store?.startTime, 'HH:mm a') : time,
    endTime: store?.endTime ? moment(store?.endTime, 'HH:mm a') : time,
  };
};

/* eslint-disable max-lines */
import React from 'react';
import { Popconfirm, Space, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { StyledDeleteOutlined } from './listing.styles';
import { dateFormat } from 'configs';
import { Colors } from 'constant';

export const categoryTableColumn = (confirmDelete) => [
  {
    title: 'Name',
    render: (category) => <>{category?.name?.en}</>,
    width: 60,
    key: 'name.en',
    align: 'center',
  },
  // {
  //   title: 'Arabic name',
  //   render: (category) => <>{category?.name?.ar}</>,
  //   width: 60,
  //   key: 'name.ar',
  //   align: 'center',
  // },
  {
    title: 'Items',
    render: (category) => (
      <>
        {category?.additions?.map((cat, index) => {
          return (
            <p key={index} style={{ marginBottom: '1px' }}>
              {cat.name.en}, {cat.name.ar} (${cat.price})
            </p>
          );
        })}
      </>
    ),
    key: 'color',
    width: 80,
    align: 'center',
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 80,
    render: (row) => dateFormat(row),
    align: 'center',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 50,
    align: 'center',
  },
  {
    title: 'Max Items',
    dataIndex: 'maxItems',
    key: 'maxItems',
    width: 50,
    align: 'center',
  },
  {
    title: 'Action',
    key: 'action',
    width: 50,
    align: 'center',
    fixed: 'right',
    render: (category) => (
      <Space size="middle">
        <Popconfirm
          placement="topRight"
          title="Are you sure! delete this category?"
          onConfirm={() => confirmDelete(category)}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title="Delete">
            <StyledDeleteOutlined />
          </Tooltip>
        </Popconfirm>
        <Link
          className="bold"
          state={{ category: category }}
          to="/dashboard/addAdditionalCategory"
        >
          <EditOutlined style={{ color: Colors.LIGHT_BLACK }} />
        </Link>
      </Space>
    ),
  },
];

export const getSortType = (sorter) => {
  if (!sorter.order) {
    return null;
  }
  switch (sorter.columnKey) {
    case 'createdAt':
      return sorter.order === 'ascend' ? 'firstCreated' : 'lastCreated';
    default:
      return null;
  }
};

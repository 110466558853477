import { createAsyncThunk } from '@reduxjs/toolkit';
import { orderAPIs } from 'services/api';
import axiosInstance from 'services/apiService';

export const getOrders = createAsyncThunk('orders/get', async (payload) => {
  const { data } = await axiosInstance({
    ...orderAPIs.get,
    params: payload,
  });
  return data;
});

export const getOrderDetail = createAsyncThunk(
  'orders/getDetail',
  async ({ id }) => {
    const { data } = await axiosInstance({
      ...orderAPIs.getDetail,
      url: `order/${id}/`,
    });
    return data;
  }
);

export const updateOrderStatus = createAsyncThunk(
  'orders/updateStatus',
  async (payload) => {
    const { data } = await axiosInstance({
      ...orderAPIs.updateStatus,
      data: payload,
    });
    return data;
  }
);

export const deleteOrder = createAsyncThunk('order/delete', async ({ id }) => {
  const api = orderAPIs.delete;
  const { data } = await axiosInstance({
    ...api,
    url: `order/remove/${id}/`,
  });
  return data.message;
});

export const getUserOrders = createAsyncThunk('order/users', async ({ id }) => {
  const api = orderAPIs.userOrders;
  const { data } = await axiosInstance({
    ...api,
    url: `order/all/${id}/`,
    // params: payload,
  });
  return data;
});

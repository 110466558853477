import { PlusOutlined, BellOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Colors } from 'constant';

export const AddIcon = styled(PlusOutlined)`
  margin-right: 7px;
`;

export const SendIcon = styled(BellOutlined)`
  margin-right: 7px;
`;

export const AddButton = styled.button`
  margin-left: auto;
  padding: 4px 18px;
  cursor: pointer;
  border: 1px solid ${Colors.GREY};
  background-color: transparent;
  color: ${Colors.BLACK};
  font-size: 1.2em;
  box-shadow: 0 6px 6px rgb(0 0 0 / 1%);
  :hover {
    background-color: #f8f8f8;
    box-shadow: 0px 10px 20px rgb(153 158 156 / 20%);
  }
`;

/* eslint-disable max-lines */
import React from 'react';
import { dateFormat } from 'configs';
import { Space, Tooltip } from 'antd';
import { ReadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
const ExcelJS = require('exceljs');

export const usersTableColumn = () => [
  {
    title: 'Fullname',
    width: 50,
    key: 'name',
    render: (user) => (
      <>
        {user?.firstname || '-'} {user?.lastname || '-'}
      </>
    ),

    align: 'center',
  },
  {
    title: 'Email',
    width: 70,
    key: 'name',
    render: (user) => <>{user.email} </>,

    align: 'center',
  },
  {
    title: 'Mobile Number',
    width: 50,
    key: 'name',
    render: (user) => (
      <>
        ({user?.countryCode || '--'}){user.mobile}
      </>
    ),

    align: 'center',
  },
  {
    title: 'DOB',
    render: (user) => <>{dateFormat(user.dateOfBirth)}</>,
    width: 70,
    key: 'dob',
    align: 'center',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 40,
    key: 'status',
    align: 'center',
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 60,
    render: (row) => dateFormat(row),
    align: 'center',
  },
  {
    title: 'Details',
    key: 'action',
    width: 40,
    align: 'center',
    fixed: 'right',
    render: (user) => (
      <Space size="middle">
        <Link
          className="bold"
          state={{ userId: user?._id }}
          to="/dashboard/customerDetail"
        >
          <Tooltip title="Detail">
            <ReadOutlined />
          </Tooltip>
        </Link>
      </Space>
    ),
  },
];

export const exportExcelFile = (data, name) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('My Sheet');
  sheet.properties.defaultRowHeight = 20;
  sheet.properties.defaultCellStyle = {};
  sheet.properties.defaultCellStyle.alignment = { horizontal: 'left' };

  sheet.getRow(1).font = {
    name: 'roboto',
    family: 4,
    size: 16,
    bold: true,
  };

  sheet.columns = [
    {
      header: 'Fullname',
      key: 'fullname',
      width: 20,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Email',
      key: 'email',
      width: 42,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Mobile Number',
      key: 'phone',
      width: 20,
      style: {
        alignment: {
          horizontal: 'left',
        },
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'DOB',
      key: 'dob',
      width: 30,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Status',
      key: 'status',
      width: 12,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Created Date',
      key: 'createdAt',
      width: 30,
      style: {
        font: {
          size: 12,
        },
      },
    },
  ];

  const promise = Promise.all(
    data.map((item) => {
      sheet.addRow({
        fullname: `${item?.firstname} ${item?.lastname}`,
        email: item?.email,
        phone: item?.mobile,
        dob: item?.dateOfBirth,
        status: item?.status,
        createdAt: item?.createdAt,
      });
      return null;
    })
  );

  promise.then(() => {
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = name?.toString() || 'download.xlsx';
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  });
};

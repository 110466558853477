import React from 'react';
import styled from 'styled-components';
import LogoImg from 'assets/images/logo.png';

export default function Logo() {
  return <SidebarLogo src={LogoImg} alt="logo" />;
}

const SidebarLogo = styled.img`
  width: 5rem;
  display: block;
  margin-top: 1rem;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { StyledRow, SearchField } from './SearchHeader.styled';

const SearchHeader = ({ isSearch = true, handleSearch }) => {
  return (
    <StyledRow>
      <Col span={6}>
        {isSearch && (
          <SearchField
            size="large"
            placeholder="Search...."
            onChange={handleSearch}
            prefix={<SearchOutlined />}
          />
        )}
      </Col>
    </StyledRow>
  );
};

SearchHeader.propTypes = {
  handleSearch: PropTypes.func,
  isSearch: PropTypes.bool,
};

export default SearchHeader;

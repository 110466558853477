import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddButton, AddIcon, SendIcon } from 'modules/requests/requests.styles';
import { Colors } from 'constant';
import { getSkipCount } from 'utils';
import { message, Card } from 'antd';
import { notificationTableColumn } from './listing.helper';
import { TableView } from '../../../components/StyledLayout/Layout.styled';

import SearchHeader from '../../../components/SearchHeader';
import {
  deleteNotification,
  getNotifications,
} from 'redux/actions/notificationActions';
import {
  selectAllNotifications,
  selectNotificationCount,
  selectNotificationLoading,
} from 'redux/reducers/notificationReducer';

const Listing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allNotifications = useSelector(selectAllNotifications);
  const isLoading = useSelector(selectNotificationLoading);
  const allNotificationsListCount = useSelector(selectNotificationCount);

  const initialPageConfig = {
    current: 1,
    pageSize: 10,
    limit: 10,
    skip: getSkipCount(1, 10),
  };
  const [pageConfig, setPageConfig] = useState(initialPageConfig);
  const onChange = (pagination, filters) => {
    const newPageConfig = {
      current: pagination.current,
      pageSize: pagination.pageSize,
      limit: pagination.pageSize,
      skip: getSkipCount(pagination.current, pagination.pageSize),
      status: filters?.status ? filters.status[0] : '',
      userType: filters?.userType ? filters.userType[0] : '',
      searchKey: pageConfig?.searchKey,
    };
    setPageConfig(newPageConfig);
  };

  useEffect(() => {
    dispatch(getNotifications(pageConfig));
  }, [dispatch, pageConfig]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onNotificationDelete = async (notification) => {
    try {
      const body = {
        id: notification._id,
      };
      await dispatch(deleteNotification(body));
      message.success('Notification deleted successfully');
    } catch (err) {
      message.error('Error while deleting Notification');
    }
  };

  const notificationColumns = notificationTableColumn(onNotificationDelete);

  return (
    <>
      <SearchHeader isSearch={false} />
      <Card
        title="Notifications"
        bordered={true}
        extra={
          <>
            <AddButton
              color={Colors.YELLOW}
              onClick={() => navigate('/dashboard/sendNotifications')}
              className="space-align-block"
              style={{ marginRight: '8px' }}
            >
              <SendIcon />
              Send Notification
            </AddButton>
            <AddButton
              color={Colors.YELLOW}
              onClick={() => navigate('/dashboard/addNotifications')}
              className="space-align-block"
            >
              <AddIcon />
              New
            </AddButton>
          </>
        }
      >
        <TableView
          columns={notificationColumns}
          dataSource={allNotifications}
          pagination={{
            position: ['none', 'bottomRight'],
            total: allNotificationsListCount,
            showSizeChanger: false,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} Notifications`,
            showQuickJumper: true,
            defaultPageSize: 50,
            ...pageConfig,
          }}
          scroll={{ x: 1000 }}
          onChange={onChange}
          loading={isLoading}
          bordered={true}
        />
      </Card>
    </>
  );
};
Listing.propTypes = {};

export default Listing;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { userAPIs } from 'services/api';
import axiosInstance from 'services/apiService';

export const getUsers = createAsyncThunk('users/get', async (payload) => {
  const { data } = await axiosInstance({
    ...userAPIs.get,
    params: payload,
  });
  return data;
});

export const getCustomerDetail = createAsyncThunk(
  'customer/getDetail',
  async ({ id }) => {
    const { data } = await axiosInstance({
      ...userAPIs.getDetail,
      url: `allUsers/customers/${id}/`,
    });
    return data;
  }
);

import React, { useContext, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import socketio from 'socket.io-client';
import { getOrders } from 'redux/actions/orderActions';

const SocketContext = React.createContext();
const SocketProvider = ({ children }) => {
  const dispatch = useDispatch();
  const socketRef = useRef(null);
  const authToken = useSelector((store) => store.auth.authToken);

  const handleOrderUpdate = useCallback(
    (order) => {
      dispatch(
        getOrders({
          current: 1,
          pageSize: 10,
          limit: 10,
          skip: 0,
        })
      );
    },
    [dispatch]
  );

  const reconnect = useCallback(async () => {
    // remove all socket listeners
    // disconnects from socket
    if (socketRef.current) {
      socketRef.current.removeAllListeners();
      socketRef.current.disconnect();
    }

    // delete current socketRef
    if (!authToken) {
      return (socketRef.current = null);
    }

    // connect to a new socket
    socketRef.current = socketio(process.env.REACT_APP_API_URL, {
      extraHeaders: {
        'auth-token': authToken,
      },
    });

    socketRef.current.on('newOrderRoom', handleOrderUpdate);

    return () => {
      socketRef.current.removeAllListeners();
      socketRef.current.disconnect();
    };
  }, [authToken, handleOrderUpdate]);

  useEffect(() => {
    reconnect();
  }, [authToken, reconnect]);
  return (
    <SocketContext.Provider value={socketRef}>
      {children}
    </SocketContext.Provider>
  );
};

SocketProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const useSocket = () => {
  const socketRef = useContext(SocketContext);
  return socketRef?.current || null;
};

export default SocketProvider;

import React from 'react';
import moment from 'moment';
import { Space, Tooltip } from 'antd';
import { ReadOutlined } from '@ant-design/icons';

const productFormat = (redeemedProduct) => {
  return (
    <>
      {redeemedProduct?.name?.en}
      {redeemedProduct?.additionalInfo && (
        <p style={{ marginBottom: '0px' }}>{redeemedProduct?.additionalInfo}</p>
      )}
    </>
  );
};

export const RewardsTableColumn = () => [
  {
    title: 'Redeemed Points',
    dataIndex: 'redeemedPoints',
    key: 'redeemedPoints',
    width: 60,
    align: 'left',
  },
  {
    title: 'Redeemed Amount',
    render: (item) => <>${item?.redeemedPointsInCurrency}</>,
    key: 'amount',
    width: 60,
    align: 'left',
  },
  {
    title: 'Date',
    render: (item) => <>{moment(item?.lastRedeemDate).format('DD/MM/YYYY')}</>,
    key: 'lastRedeemDate',
    width: 40,
    align: 'left',
  },
];

export const CupsTableColumn = () => [
  {
    title: 'Product',
    render: (item) => productFormat(item?.redeemedProduct),
    key: 'name',
    width: 60,
    align: 'left',
  },
  {
    title: 'Category',
    render: (item) => <>{item?.redeemedProduct?.categoryName}</>,
    key: 'category',
    width: 60,
    align: 'left',
  },
  {
    title: 'Size',
    render: (item) => <>{item?.redeemedProduct?.size}</>,
    key: 'price',
    width: 60,
    align: 'left',
  },
  {
    title: 'Price',
    render: (item) => <>${item?.redeemedProduct?.price}</>,
    key: 'price',
    width: 60,
    align: 'left',
  },
  {
    title: 'Date',
    render: (item) => <>{moment(item?.createdAt).format('DD/MM/YYYY')}</>,
    key: 'lastRedeemDate',
    width: 40,
    align: 'left',
  },
];

export const BirthdayRewardsTableColumn = () => [
  {
    title: 'Product',
    render: (item) => productFormat(item?.redeemedProduct),
    key: 'name',
    width: 60,
    align: 'left',
  },
  {
    title: 'Category',
    render: (item) => <>{item?.redeemedProduct?.categoryName}</>,
    key: 'category',
    width: 60,
    align: 'left',
  },
  {
    title: 'Size',
    render: (item) => <>{item?.redeemedProduct?.size}</>,
    key: 'price',
    width: 60,
    align: 'left',
  },
  {
    title: 'Price',
    render: (item) => <>${item?.redeemedProduct?.price}</>,
    key: 'price',
    width: 60,
    align: 'left',
  },
  {
    title: 'Date',
    render: (item) => <>{moment(item?.createdAt).format('DD/MM/YYYY')}</>,
    key: 'lastRedeemDate',
    width: 40,
    align: 'left',
  },
];

export const EventTableColumn = (
  visible,
  setVisible,
  RedeemedEventProducts
) => [
  {
    title: 'Sub Total',
    render: (item) => <>{item?.subtotal}</>,
    key: 'total',
    width: 60,
    align: 'left',
  },
  {
    title: 'Discount Total',
    render: (item) => <>{item?.discountTotal}</>,
    key: 'discountTotal',
    width: 60,
    align: 'left',
  },
  {
    title: 'Total',
    render: (item) => <>{item?.total}</>,
    key: 'discountTotal',
    width: 60,
    align: 'left',
  },
  {
    title: 'Ordered Item',
    render: (item) => <>{item?.redeemedProducts?.length}</>,
    key: 'price',
    width: 60,
    align: 'left',
  },
  {
    title: 'Date',
    render: (item) => <>{moment(item?.createdAt).format('DD/MM/YYYY')}</>,
    key: 'lastRedeemDate',
    width: 40,
    align: 'left',
  },
  {
    title: 'Details',
    key: 'action',
    width: 40,
    align: 'center',
    fixed: 'right',
    render: (item) => (
      <>
        <Space size="middle">
          <Tooltip title="Detail">
            <ReadOutlined onClick={() => setVisible(true)} />
          </Tooltip>
        </Space>
        <RedeemedEventProducts
          visible={visible}
          setVisible={setVisible}
          redeemedEventDiscount={item?.redeemedProducts}
        />
      </>
    ),
  },
];

export const RedeemedEventProductsTableColumn = () => [
  {
    title: 'Product',
    render: (item) => productFormat(item),
    key: 'name',
    width: 60,
    align: 'center',
  },
  {
    title: 'Category',
    render: (item) => <>{item?.categoryName?.en}</>,
    key: 'category',
    width: 60,
    align: 'center',
  },
  {
    title: 'Size',
    render: (item) => <>{item?.size}</>,
    key: 'price',
    width: 50,
    align: 'center',
  },
  {
    title: 'Discount Percentage',
    render: (item) => <>{item?.discountPercentage * 100}%</>,
    key: 'price',
    width: 110,
    align: 'center',
  },
  {
    title: 'Price',
    render: (item) => <>${item?.price}</>,
    key: 'price',
    width: 50,
    align: 'center',
  },
  {
    title: 'Discounted Price',
    render: (item) => <>${item?.discountedPrice.toFixed(2)}</>,
    key: 'price',
    width: 60,
    align: 'center',
  },
];

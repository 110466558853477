import { createAsyncThunk } from '@reduxjs/toolkit';
import { notificationAPIs } from 'services/api';
import axiosInstance from 'services/apiService';

export const addNotifications = createAsyncThunk(
  'notification/add',
  async (payload) => {
    const { data } = await axiosInstance({
      ...notificationAPIs.add,
      data: payload,
    });
    return data;
  }
);

export const deleteNotification = createAsyncThunk(
  'notification/delete',
  async ({ id }) => {
    const api = notificationAPIs.delete;
    const { data } = await axiosInstance({
      ...api,
      url: `notifications/${id}/`,
    });
    return data.message;
  }
);

export const getNotifications = createAsyncThunk(
  'notification/get',
  async (payload) => {
    const { data } = await axiosInstance({
      ...notificationAPIs.get,
      params: payload,
    });
    return data;
  }
);

export const updateNotifications = createAsyncThunk(
  'notification/update',
  async (payload) => {
    const api = notificationAPIs.update;

    const { data } = await axiosInstance({
      ...api,
      url: `notifications/${payload._id}/`,
      data: payload.values,
    });
    return data;
  }
);

export const sendNotification = createAsyncThunk(
  'notification/send',
  async (payload) => {
    const { data } = await axiosInstance({
      ...notificationAPIs.send,
      data: payload,
    });
    return data;
  }
);

export const getUsers = createAsyncThunk('notification/getUsers', async () => {
  const { data } = await axiosInstance({
    ...notificationAPIs.getUsers,
  });
  return data;
});

/* eslint-disable max-lines */
import React from 'react';
import { Colors } from 'constant';
import { Popconfirm, Space, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { StyledDeleteOutlined } from './listing.styles';
import { dateFormat } from 'configs';
const ExcelJS = require('exceljs');

export const ProductTableColumn = (confirmDelete) => [
  {
    title: 'Name',
    render: (product) => <>{product?.name?.en}</>,
    width: 70,
    key: 'name',
    align: 'center',
    sorter: (a, b) => a.name?.en.length - b.name?.en.length,
  },
  {
    title: 'Ingredients',
    render: (product) => <>{product?.description?.en}</>,
    width: 70,
    key: 'description',
    align: 'center',
  },
  {
    title: 'Price',
    render: (product) => <b>${product?.price}</b>,
    key: 'price',
    width: 70,
    align: 'center',
    sorter: (a, b) => a.price < b.price,
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 80,
    render: (row) => dateFormat(row),
    align: 'center',
  },
  {
    title: 'Status',
    render: (product) => <>{product?.status || 'Enabled'}</>,
    key: 'status',
    width: 50,
    align: 'center',
  },
  {
    title: 'Action',
    key: 'action',
    width: 50,
    align: 'center',
    fixed: 'right',
    render: (product) => (
      <Space size="middle">
        <Popconfirm
          placement="topRight"
          title="Are you sure! delete this product?"
          onConfirm={() => confirmDelete(product)}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title="Delete">
            <StyledDeleteOutlined />
          </Tooltip>
        </Popconfirm>
        <Link
          className="bold"
          state={{ product: product }}
          to="/dashboard/addProduct"
        >
          <EditOutlined style={{ color: Colors.LIGHT_BLACK }} />
        </Link>
      </Space>
    ),
  },
];

export const getSortType = (sorter) => {
  if (!sorter.order) {
    return null;
  }
  switch (sorter.columnKey) {
    case 'createdAt':
      return sorter.order === 'ascend' ? 'firstCreated' : 'lastCreated';
    default:
      return null;
  }
};

export const exportExcelFile = (data, name) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('My Sheet');
  sheet.properties.defaultRowHeight = 20;
  sheet.properties.defaultCellStyle = {};
  sheet.properties.defaultCellStyle.alignment = { horizontal: 'left' };

  sheet.getRow(1).font = {
    name: 'roboto',
    family: 4,
    size: 16,
    bold: true,
  };

  sheet.columns = [
    {
      header: 'Name',
      key: 'name',
      width: 20,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Ingredients',
      key: 'ingredients',
      width: 80,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Price',
      key: 'price',
      width: 20,
      style: {
        alignment: {
          horizontal: 'left',
        },
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Created Date',
      key: 'date',
      width: 30,
      style: {
        font: {
          size: 12,
        },
      },
    },
    {
      header: 'Status',
      key: 'status',
      width: 20,
      style: {
        font: {
          size: 12,
        },
      },
    },
  ];

  const promise = Promise.all(
    data.map((item) => {
      sheet.addRow({
        name: item?.name?.en,
        ingredients: item?.description?.en,
        price: item?.price,
        date: item?.createdAt,
        status: item?.status || 'enabled',
      });
      return null;
    })
  );

  promise.then(() => {
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = name?.toString() || 'download.xlsx';
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  });
};

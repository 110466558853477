import React from 'react';
import PropTypes from 'prop-types';
import { Colors } from 'constant';
import { StyledText } from './Text.styled';

const Text = ({
  type = 'NORMAL',
  ar = false,
  size,
  children,
  color = Colors.BLACK,
  className,
}) => (
  <StyledText type={type} className={className} size={size} color={color}>
    {children}
  </StyledText>
);

Text.propTypes = {
  type: PropTypes.string,
  ar: PropTypes.bool,
  size: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  className: PropTypes.any,
};

export default Text;

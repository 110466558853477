import React from 'react';
import { Form, Typography, Row, Card, message } from 'antd';
import { login, loginWithGoogle } from 'redux/actions/authActions';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import Logo from 'assets/images/logo.png';
import { googleSignIn } from 'utils/firebase';
import { StyledWrapper, SidebarLogo } from './index.css.js';
// import { emailValidation, passwordValidation } from './helper';
const { Title } = Typography;

const Login = () => {
  const dispatch = useDispatch();
  // const { loginLoading } = useSelector((store) => store.auth);
  const [googleLoading, setGoogleLoading] = React.useState(false);

  const onFinish = (formValues) => {
    dispatch(login(formValues)).then((res) => {
      if (res?.error) {
        message.error(res.error.message);
      }
    });
  };

  const googleSignInHandler = async () => {
    try {
      setGoogleLoading(true);
      const idToken = await googleSignIn();
      await dispatch(loginWithGoogle({ token: idToken })).unwrap();
      setGoogleLoading(false);
    } catch (err) {
      setGoogleLoading(false);
      message.error(err.message);
    }
  };

  return (
    <StyledWrapper>
      <Card className="card-style" hoverable={true}>
        <Row justify="center">
          <div className="mb-1">
            <SidebarLogo src={Logo} alt="logo" />
          </div>
          <div>
            <Title
              level={4}
              style={{ textAlign: 'center', marginBottom: '0px' }}
            >
              Welcome to Smoothie factory Admin Panel
            </Title>
            <Title
              level={5}
              style={{ marginTop: '0.5rem', textAlign: 'center' }}
            >
              Login using google account
            </Title>
          </div>
          <Form onFinish={onFinish}>
            {/* <Form.Item name="email" type="email" rules={emailValidation}>
              <Input
                type="email"
                placeholder="Email"
                className="top-right-radius top-left-radius"
              />
            </Form.Item>

            <Form.Item name="password" rules={passwordValidation}>
              <Input.Password
                type="password"
                placeholder="Password"
                className="input-password bottom-right-radius bottom-left-radius"
              />
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" loading={loginLoading} fullWidth={true}>
                <span style={{ color: '#ffffff' }}>Login</span>
              </Button>
            </Form.Item> */}
            <Form.Item className="mt-1">
              <Button
                onClick={googleSignInHandler}
                fullWidth={true}
                loading={googleLoading}
              >
                <span style={{ color: '#ffffff' }}>Google</span>
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Card>
    </StyledWrapper>
  );
};
export default Login;

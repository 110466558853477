import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { StyledWrapper } from './Detail.styled';

const DetailInfo = ({ customer }) => {
  return (
    <StyledWrapper>
      <div className="layout-styled">
        <h3>Details</h3>
        <p className="lessMargin">
          Email: <b>{customer?.email}</b>
        </p>
        <p className="lessMargin">
          Phone Number: {customer?.countryCode}{' '}
          {customer?.mobile || 'No number added'}
        </p>
        <p className="lessMargin">
          Name: {customer?.firstname} {customer?.lastname}
        </p>
        <p className="lessMargin">Gender: {customer?.gender || '---'}</p>
        <p className="lessMargin">
          DateOfBirth: {moment(customer?.dateOfBirth).format('DD/MM/YYYY')}
        </p>
      </div>
    </StyledWrapper>
  );
};
DetailInfo.propTypes = {
  customer: PropTypes.object,
};

export default DetailInfo;

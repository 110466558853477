import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { StyledWrapper } from './Detail.styled';

const CustomerDetail = ({ orderDetails }) => {
  return (
    <StyledWrapper>
      <div className="layout-styled">
        <h3>Customer detail</h3>
        <p className="NoMargin">
          Email: <b>{orderDetails?.customerId?.email}</b>
        </p>
        <p className="NoMargin">
          Phone Number: {orderDetails?.customerId?.countryCode}{' '}
          {orderDetails?.customerId?.mobile || 'No number added'}
        </p>
        <p className="NoMargin">
          Name: {orderDetails?.customerId?.firstname}{' '}
          {orderDetails?.customerId?.lastname}
        </p>
        <p className="NoMargin">
          Gender: {orderDetails?.customerId?.gender || '---'}
        </p>
        <p className="NoMargin">
          DateOfBirth:{' '}
          {moment(orderDetails?.customerId?.dateOfBirth).format('DD/MM/YYYY')}
        </p>
      </div>
    </StyledWrapper>
  );
};
CustomerDetail.propTypes = {
  orderDetails: PropTypes.object,
};

export default CustomerDetail;

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

const MessageModal = (data) => {
  return (
    <>
      <Modal
        title="Message Detail"
        visible={data?.visible}
        onCancel={() => data?.setVisible(false)}
        onOk={() => data?.setVisible(false)}
        width={500}
      >
        <div className="layout-styled">
          <p>{data?.message}</p>
        </div>
      </Modal>
    </>
  );
};

MessageModal.propTypes = {
  data: PropTypes?.any,
};

export default MessageModal;

export const orderAPIs = {
  updateStatus: {
    url: 'order/updateStatus',
    method: 'patch',
    isAuthEnabled: true,
  },
  get: {
    url: 'order/all',
    method: 'get',
    isAuthEnabled: true,
  },
  delete: {
    url: 'order/remove',
    method: 'delete',
    isAuthEnabled: true,
  },
  getDetail: {
    url: 'order',
    method: 'get',
    isAuthEnabled: true,
  },
};

import {
  StopOutlined,
  DeleteOutlined,
  CheckSquareOutlined,
} from '@ant-design/icons';
import { Colors } from '../../../constant/Colors';
import styled from 'styled-components';

export const StyledStopOutline = styled(StopOutlined)`
  color: ${Colors.BLUE};
`;

export const StyledCheckSquareOutlined = styled(CheckSquareOutlined)`
  color: ${Colors.DARK_GREEN};
`;

export const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${Colors.LIGHT_BLACK};
`;

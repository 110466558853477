import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Input, Button, Typography, Radio, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { addCategory, updateCategory } from 'redux/actions/categoryActions';
import { StyledLayout } from '../../../components/StyledLayout/Layout.styled';
import UploadImage from '../../../components/Upload';
import GoBack from '../../../components/GoBack';

const { Title } = Typography;

const AddCategoryModal = () => {
  const [fileObject, setFileObject] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { category } = location?.state || {};

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleFinish = async (values) => {
    try {
      setLoading(true);
      const file = typeof values?.icon === String ? values?.icon : fileObject;
      const formData = new FormData();
      formData.append(
        'name',
        JSON.stringify({
          en: values['name.en'],
          ar: values['name.ar'],
        })
      );
      formData.append('color', values?.color);
      formData.append('icon', file);
      if (values?.status) formData.append('status', values?.status);

      if (category?._id) {
        await dispatch(
          updateCategory({ formData, id: category?._id })
        ).unwrap();
      } else {
        await dispatch(addCategory(formData)).unwrap();
      }

      form.resetFields();
      setLoading(false);
      navigate('/dashboard/category');
    } catch (e) {
      // message.error('Error while adding category');
    }
  };

  return (
    <>
      <GoBack linkUrl="/dashboard/category" />
      <StyledLayout>
        <Title level={4}>Add new category</Title>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          initialValues={{
            ...category,
            'name.en': category?.name?.en,
            'name.ar': category?.name?.ar,
          }}
          className="formView"
        >
          <Form.Item label="Name" name="name.en" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          {/* <Form.Item
            label="Name in arabic"
            name="name.ar"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item> */}

          <Form.Item
            label="Background color"
            name="color"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Icon" name="icon">
            <UploadImage
              setFileObject={setFileObject}
              fileObject={fileObject}
              defaultImage={category?.icon}
            />
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Radio.Group defaultValue={category?.status || 'Enabled'}>
              <Space direction="vertical">
                <Radio value={'Enabled'}>Enabled</Radio>
                <Radio value={'Disabled'}>Disabled</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="button"
              loading={loading}
            >
              Submit
            </Button>
            <Button
              type="secondary"
              htmlType="button"
              className="cancelButton"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </StyledLayout>
    </>
  );
};

export default AddCategoryModal;

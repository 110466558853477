import React from 'react';
import { dateFormat } from 'configs';
import ConfirmationModal from './ConfirmationModal';

export const usersTableColumn = (
  confirmInactive,
  confirmDelete,
  confirmActivate
) => [
  {
    title: 'Full Name',
    dataIndex: 'fullname',
    align: 'center',
    width: 50,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: 80,
    align: 'center',
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    width: 50,
    render: (row) => dateFormat(row),
    // sorter: true,
  },
  {
    title: 'Role',
    dataIndex: 'role',
    width: 30,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 40,
    align: 'center',
  },
  {
    title: 'Action',
    key: '',
    width: 50,
    align: 'center',
    fixed: 'right',
    render: (user) => (
      <ConfirmationModal
        user={user}
        {...{ confirmDelete, confirmActivate, confirmInactive }}
      />
    ),
  },
];

import { createAsyncThunk } from '@reduxjs/toolkit';
import { additionalCategoryAPIs } from 'services/api';
import axiosInstance from 'services/apiService';

export const addAdditionalCategory = createAsyncThunk(
  'additionalCategory/add',
  async (payload) => {
    const { data } = await axiosInstance({
      ...additionalCategoryAPIs.add,
      data: payload,
    });
    return data;
  }
);

export const getAdditionalCategories = createAsyncThunk(
  'admin/getAAdditionalCategories',
  async (payload, { getState }) => {
    const { data } = await axiosInstance({
      ...additionalCategoryAPIs.get,
      params: payload,
    });
    return data;
  }
);

export const getAllAdditionalCategories = createAsyncThunk(
  'admin/getAllAdditionalCategories',
  async () => {
    const { data } = await axiosInstance({
      ...additionalCategoryAPIs.getAll,
    });
    return data;
  }
);

export const deleteAdditionalCategory = createAsyncThunk(
  'admin/deleteAdditionalCategory',
  async ({ categoryId }, { getState }) => {
    const api = additionalCategoryAPIs.delete;
    const { data } = await axiosInstance({
      ...api,
      url: `categoryAdditions/${categoryId}/`,
    });
    return data.message;
  }
);

export const updateAdditionalCategory = createAsyncThunk(
  'admin/updateAdditionalCategory',
  async (payload) => {
    const api = additionalCategoryAPIs.update;

    const { data } = await axiosInstance({
      ...api,
      url: `categoryAdditions/${payload.id}/`,
      data: payload.data,
    });
    return data;
  }
);

export const deleteAddition = createAsyncThunk(
  'admin/deleteAddition',
  async ({ categoryId }, { getState }) => {
    const api = additionalCategoryAPIs.deleteAddition;
    const { data } = await axiosInstance({
      ...api,
      url: `additions/${categoryId}/`,
    });
    return data.message;
  }
);

export const categoryAPIs = {
  add: {
    url: 'category',
    method: 'post',
    isAuthEnabled: true,
  },
  delete: {
    url: 'category',
    method: 'delete',
    isAuthEnabled: true,
  },
  get: {
    url: 'category',
    method: 'get',
    isAuthEnabled: true,
  },
  getAll: {
    url: 'category/all',
    method: 'get',
    isAuthEnabled: true,
  },
  update: {
    url: 'category',
    method: 'patch',
    isAuthEnabled: true,
  },
};

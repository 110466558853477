import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSkipCount } from 'utils';
import { Card } from 'antd';
import { contactDataTableColumn } from './listing.helper';
import { TableView } from '../../components/StyledLayout/Layout.styled';
import SearchHeader from '../../components/SearchHeader';
import { getContactData } from 'redux/actions/contactDataActions';
import {
  selectAllContactData,
  selectContactDataCount,
  selectContactDataLoading,
} from 'redux/reducers/contactDataReducer';
import MessageModal from './messageModal';

const Listing = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState(false);
  const allContactData = useSelector(selectAllContactData);
  const isLoading = useSelector(selectContactDataLoading);
  const allContactDataListCount = useSelector(selectContactDataCount);
  const initialPageConfig = {
    current: 1,
    pageSize: 10,
    limit: 10,
    skip: getSkipCount(1, 10),
  };
  const [pageConfig, setPageConfig] = useState(initialPageConfig);
  const onChange = (pagination, filters) => {
    const newPageConfig = {
      current: pagination.current,
      pageSize: pagination.pageSize,
      limit: pagination.pageSize,
      skip: getSkipCount(pagination.current, pagination.pageSize),
      status: filters?.status ? filters.status[0] : '',
      userType: filters?.userType ? filters.userType[0] : '',
      searchKey: pageConfig?.searchKey,
    };
    setPageConfig(newPageConfig);
  };

  useEffect(() => {
    dispatch(getContactData(pageConfig));
  }, [dispatch, pageConfig]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contactDataColumns = contactDataTableColumn(setVisible, setMessage);
  return (
    <>
      <SearchHeader isSearch={false} />
      <Card title="Contact Data" bordered={true}>
        <TableView
          columns={contactDataColumns}
          dataSource={allContactData}
          pagination={{
            position: ['none', 'bottomRight'],
            total: allContactDataListCount,
            showSizeChanger: false,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} Data`,
            showQuickJumper: true,
            defaultPageSize: 50,
            ...pageConfig,
          }}
          scroll={{ x: 1000 }}
          onChange={onChange}
          loading={isLoading}
          bordered={true}
        />
      </Card>
      <MessageModal
        visible={visible}
        setVisible={setVisible}
        message={message}
      />
    </>
  );
};
Listing.propTypes = {};

export default Listing;

import React from 'react';
import { dateFormat } from 'configs';
import { EyeOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Colors } from 'constant';

export const contactDataTableColumn = (setVisible, setMessage) => [
  {
    title: 'Fullname',
    dataIndex: 'fullname',
    width: 70,
    key: 'fullname',
    align: 'center',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: 70,
    key: 'email',
    align: 'center',
  },
  {
    title: 'Message',
    render: (row) => (
      <>
        {row?.message?.substring(0, 50)}
        <span
          className="bold"
          onClick={() => {
            setVisible(true);
            setMessage(row?.message);
          }}
        >
          <Tooltip title="View Message">
            <EyeOutlined
              style={{ marginLeft: '3px', color: Colors.GEEK_BLUE }}
            />
          </Tooltip>
        </span>
      </>
    ),
    key: 'message',
    width: 70,
    align: 'center',
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 80,
    render: (row) => dateFormat(row),
    align: 'center',
  },
];

import { Table } from 'antd';
import { Colors } from 'constant';
import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';

export const NotificationTable = styled(Table)`
  margin-top: 4rem;
  .ant-table-thead > tr > th {
    background-color: ${Colors.WHITE};
    border-bottom: 1px solid ${Colors.BORDER_GREY};
  }
`;

export const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${Colors.LIGHT_BLACK};
`;

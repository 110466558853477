import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Typography, message } from 'antd';
import {
  addNotifications,
  updateNotifications,
} from 'redux/actions/notificationActions';
import GoBack from '../../../components/GoBack';
import { StyledLayout } from '../../../components/StyledLayout/Layout.styled';

const { Title } = Typography;
const NewNotification = () => {
  const location = useLocation();
  const { notifications } = location?.state || {};

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const handleFinish = async (values) => {
    try {
      const optionsObj = {
        title: values.title,
        description: {
          en: values['description.en'],
          ar: values['description.ar'],
        },
        type: values.type,
      };

      if (notifications?._id) {
        await dispatch(
          updateNotifications({ values: optionsObj, _id: notifications?._id })
        ).unwrap();
      } else {
        await dispatch(addNotifications(optionsObj)).unwrap();
      }

      form.resetFields();
      navigate('/dashboard/notifications');
    } catch (e) {
      message.error('Error while adding Notifications');
    }
  };

  return (
    <>
      <GoBack linkUrl={'/dashboard/notifications'} />
      <StyledLayout>
        <Title level={4}>Add new notification</Title>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          initialValues={{
            ...notifications,
            'description.en': notifications?.description?.en,
            'description.ar': notifications?.description?.ar,
          }}
        >
          <Form.Item label="Title" name="title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description.en"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
            label="Description in arabic"
            name="description.ar"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item> */}

          <Form.Item>
            <Button type="primary" htmlType="submit" className="button">
              Submit
            </Button>
            <Button
              type="secondary"
              htmlType="button"
              className="cancelButton"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </StyledLayout>
    </>
  );
};

export default NewNotification;

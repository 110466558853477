import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Button, Typography, message, TimePicker, Input } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  addstoreLocation,
  updatestoreLocation,
} from 'redux/actions/storeLocationActions';
import { StyledLayout } from 'components/StyledLayout/Layout.styled';
import GoBack from 'components/GoBack';
// import CustomMap from 'components/GoogleMap';
import { formatedStorelocation } from './NewLocation.helper';
import { useJsApiLoader } from '@react-google-maps/api';
const libraries = ['places'];
const SECRET_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const { Title } = Typography;

const NewStoreLocation = () => {
  const [loading, setLoading] = useState(false);
  // const [address, setAddress] = useState(null);
  const location = useLocation();
  const { storeLocation } = location?.state || {};

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { isLoaded } = useJsApiLoader({
    libraries: libraries,
    googleMapsApiKey: SECRET_KEY,
  });

  const handleFinish = async (values) => {
    try {
      setLoading(true);
      const dataObject = {
        name: values?.name,
        startTime: moment(values.startTime).format('HH:mm A'),
        endTime: moment(values.endTime).format('HH:mm A'),
        // location: address,
      };
      if (storeLocation?._id) {
        await dispatch(
          updatestoreLocation({ values: dataObject, _id: storeLocation?._id })
        ).unwrap();
      } else {
        await dispatch(addstoreLocation(dataObject)).unwrap();
      }

      form.resetFields();
      setLoading(false);
      navigate('/dashboard/storeLocation');
    } catch (e) {
      setLoading(false);
      message.error(e.message || 'Error while adding store location');
    }
  };
  const storeLocationFormat = formatedStorelocation(storeLocation);
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <>
      <GoBack linkUrl="/dashboard/storeLocation" />
      <StyledLayout>
        <Title level={4}>Add new event discount</Title>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          className="formView"
          initialValues={storeLocationFormat}
        >
          <Form.Item
            label="City name"
            name="name"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Start Time"
            name="startTime"
            rules={[{ required: true }]}
          >
            <TimePicker
              style={{
                width: '100%',
              }}
              use12Hours
              format="hh:mm a"
            />
          </Form.Item>
          <Form.Item
            label="End Time"
            name="endTime"
            rules={[{ required: true }]}
          >
            <TimePicker
              style={{
                width: '100%',
              }}
              use12Hours
              format="hh:mm a"
            />
          </Form.Item>
          {/* <div>
            <CustomMap
              setAddress={setAddress}
              defaultAddress={storeLocationFormat?.location}
              coords={storeLocationFormat?.coords}
              isLoaded={isLoaded}
            />
          </div> */}

          <Form.Item style={{ marginTop: '1rem' }}>
            <Button
              type="primary"
              htmlType="submit"
              className="button"
              loading={loading}
            >
              Submit
            </Button>
            <Button
              type="secondary"
              htmlType="button"
              className="cancelButton"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </StyledLayout>
    </>
  );
};

export default NewStoreLocation;

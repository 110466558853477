import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { UserOutlined } from '@ant-design/icons';
import { StyledWrapper } from './Detail.styled';

const EmailInfo = ({ customer }) => {
  return (
    <StyledWrapper>
      <div className="layout-styled">
        <h2 className="NoMargin">
          {customer?.firstname} {customer?.lastname} ({customer?.email}){' '}
        </h2>
        <p className="lightBlack createdCustomer">
          {' '}
          <UserOutlined className="userIcon" />
          Customer was created
        </p>
        <p> {moment(customer?.createdAt).format('MMMM DD, YYYY, hh:mm a')}</p>
      </div>
    </StyledWrapper>
  );
};
EmailInfo.propTypes = {
  customer: PropTypes.object,
};

export default EmailInfo;

import { message } from 'antd';
import axios from 'axios';
import { BASE_URL } from 'configs';
import { checkInternetError, handleError } from 'utils/handleAsyncError';

let store;

export const injectStore = (_store) => {
  store = _store;
};

const axiosInstance = axios.create({
  baseURL: `${BASE_URL}/api`,
});

const errorHandler = (error) => {
  if (!error.response) {
    message.error(checkInternetError);
    return Promise.reject(checkInternetError);
  }
  if (handleError(error)) {
    return Promise.reject(handleError(error));
  }
  return Promise.reject({ ...error });
};

const requestHandler = (config) => {
  if (config?.isAuthEnabled) {
    const authToken = store.getState().auth.authToken;
    config.headers['auth-token'] = `${authToken}`;
  } else {
    config.headers['auth-token'] = null;
  }
  return config;
};

axiosInstance.interceptors.request.use(
  (config) => requestHandler(config),
  (error) => errorHandler(error)
);

axiosInstance.interceptors.response.use(undefined, (error) =>
  errorHandler(error)
);

export default axiosInstance;

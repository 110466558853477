import React from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper } from './Detail.styled';
import { TableView } from '../../../components/StyledLayout/Layout.styled';
import { RewardsTableColumn } from './detail.helper';

const RewardsHistory = ({ customer }) => {
  const rewardsColumns = RewardsTableColumn();

  return (
    <StyledWrapper>
      <div className="layout-styled">
        <h2>
          Reward Points: {customer?.reward?.points?.total || 0}{' '}
          <span className="pointCurrency">
            (${customer?.reward?.points?.pointsInCurrency || 0})
          </span>
        </h2>
        <TableView
          columns={rewardsColumns}
          dataSource={customer?.pointsHistory}
          bordered={false}
          pagination={false}
        />
      </div>
    </StyledWrapper>
  );
};
RewardsHistory.propTypes = {
  customer: PropTypes.object,
};

export default RewardsHistory;

import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { InputNumber, Button, Input, Row, Col, Space, Radio } from 'antd';

export const itemsFields = (Form, deleteAdditions) => {
  return (
    <Form.List name="items">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field) => (
            <>
              <Form.Item
                name={[field.name, '_id']}
                style={{ display: 'none' }}
              ></Form.Item>
              <Row gutter={16} key={field.key}>
                <Col span={6}>
                  <Form.Item
                    {...field}
                    label="Item name"
                    name={[field.name, 'name.en']}
                    defaultValue={field.name.en}
                    rules={[{ required: true, message: 'Missing name' }]}
                  >
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                {/* <Col span={6}>
                  <Form.Item
                    {...field}
                    label="Item name in arabic"
                    name={[field.name, 'name.ar']}
                    rules={[{ required: true, message: 'Missing name' }]}
                  >
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                </Col> */}
                <Col span={6}>
                  <Form.Item
                    {...field}
                    label="Item price"
                    name={[field.name, 'price']}
                  >
                    <InputNumber defaultValue={0} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={[field.name, 'status']} label="Status">
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value={'Enabled'}>Enabled</Radio>
                        <Radio value={'Disabled'}>Disabled</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={2} style={{ display: 'flex', alignItems: 'center' }}>
                  <MinusCircleOutlined
                    onClick={() => {
                      deleteAdditions({ ...field });
                      remove(field.name);
                    }}
                  />
                </Col>
              </Row>
            </>
          ))}

          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Add Item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

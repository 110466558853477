import React, { useCallback, useEffect, useRef } from 'react';
// import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'routes';
import './App.less';
import { Alert } from 'antd';
import { useSocket } from '../src/providers/SocketProvider';
import NotificationSound from '../src/modules/orders/OrdersListing/notification-sound.wav';
const orderReminderAlert = {
  body: 'This is a 15 minutes prior reminder for the order received',
  title: ' Reminder: Pre- Order Received',
};
const App = () => {
  const socket = useSocket();
  const audioPlayer = useRef(null);
  const playAudio = () => {
    audioPlayer.current.play();
  };
  const showNotification = useCallback(function callback(data) {
    if (!('Notification' in window)) {
      Alert('Browser does not support desktop notification');
    } else {
      Notification.requestPermission();
    }
    var options = {
      body: data.body,
      dir: 'ltr',
    };

    new Notification(data.title, options);
    playAudio();
  }, []);

  useEffect(() => {
    socket?.on('orderReminder', () => {
      showNotification(orderReminderAlert);
    });
  }, [socket, showNotification]);

  return (
    <>
      <AppRoutes />
      <audio ref={audioPlayer} src={NotificationSound} />
    </>
  );
};

export default App;

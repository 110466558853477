import React from 'react';
import PropTypes from 'prop-types';
import { Space, Popconfirm, Tooltip } from 'antd';
import {
  StyledCheckSquareOutlined,
  StyledStopOutline,
  StyledDeleteOutlined,
} from 'modules/admins/AdminListing/list.styles';

const ConfirmationModal = ({
  user,
  confirmDelete,
  confirmActivate,
  confirmInactive,
}) => {
  const isActive = user.status === 'ACTIVE';
  return (
    <Space size="middle">
      <Popconfirm
        placement="topRight"
        title={
          isActive
            ? `Are you sure you want to inactive ${user.fullname || user.email}`
            : `Are you sure you want to activate ${
                user.fullname || user.email
              }?`
        }
        onConfirm={() => {
          isActive ? confirmInactive(user) : confirmActivate(user);
        }}
        okText="Yes"
        cancelText="No"
      >
        {user.status === 'ACTIVE' ? (
          <Tooltip title="InActivate">
            <StyledStopOutline />
          </Tooltip>
        ) : (
          <Tooltip title="Activate">
            <StyledCheckSquareOutlined />
          </Tooltip>
        )}
      </Popconfirm>
      <Popconfirm
        placement="topRight"
        title="Are you sure! delete this user?"
        onConfirm={() => confirmDelete(user)}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip title="Delete">
          <StyledDeleteOutlined />
        </Tooltip>
      </Popconfirm>
    </Space>
  );
};

ConfirmationModal.propTypes = {
  user: PropTypes.object.isRequired,
  confirmActivate: PropTypes.func,
  confirmDelete: PropTypes.func,
  confirmInactive: PropTypes.func,
};

export default ConfirmationModal;

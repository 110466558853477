/* eslint-disable max-lines */
import React from 'react';
import { Popconfirm, Space, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Colors } from 'constant';
import { Link } from 'react-router-dom';
import { StyledDeleteOutlined } from './listing.styles';
import { dateFormat } from 'configs';

export const notificationTableColumn = (confirmDelete) => [
  {
    title: 'Title',
    render: (notification) => <>{notification?.title}</>,
    width: 70,
    key: 'title',
    align: 'center',
  },

  {
    title: 'Description',
    render: (notification) => <>{notification?.description?.en}</>,
    width: 70,
    key: 'description.en',
    align: 'center',
  },
  // {
  //   title: 'Arabic description',
  //   render: (notification) => <>{notification?.description?.ar}</>,
  //   width: 70,
  //   key: 'description.ar',
  //   align: 'center',
  // },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 80,
    render: (row) => dateFormat(row),
    align: 'center',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
  },
  {
    title: 'Action',
    key: 'action',
    width: 50,
    align: 'center',
    fixed: 'right',
    render: (notification) => (
      <Space size="middle">
        <Popconfirm
          placement="topRight"
          title="Are you sure! delete this notification?"
          onConfirm={() => confirmDelete(notification)}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title="Delete">
            <StyledDeleteOutlined />
          </Tooltip>
        </Popconfirm>
        <Link
          className="bold"
          state={{ notifications: notification }}
          to="/dashboard/addNotifications"
        >
          <EditOutlined style={{ color: Colors.LIGHT_BLACK }} />
        </Link>
      </Space>
    ),
  },
];

export const Colors = {
  GREEN: '#00a854',
  YELLOW: '#FAC630',
  ORANGE: '#FB9804',
  NAVBLUE: '#2c7be5',
  WHITE: '#ffffff',
  LIGHT_BLACK: '#22373F',
  BLUE: '#3298FA',
  MAROON: '#C13584',
  JACARTA: '#4B2770',
  InputBgGray: '#F9F9F9',
  BUTTON_BORDER: '#EAE6E6',
  DARK_GREEN: '#013220',
  RED: '#ff3e3e',
  DARK_RED: '#8B0000',
  TRANSPARENT: 'transparent',
  GREY: '#E2E2E2',
  BORDER_GREY: '#D9D2D2',
  TEXT_GREY: '#CACACA',
  TEXT_DARK_GREY: '#C3BCBC',
  FOOTER_BG: '#f5f5f7',
  V2_BG: '#F1F2F6',
  SIDEBAR_GREY: '#fafafa',
  GEEK_BLUE: '#2F80ED',
  BLACK: '#000000',
  THEMEBLUE: '#edf2f9',
};

import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'underscore';
import {
  getAdditionalCategories,
  deleteAdditionalCategory,
} from 'redux/actions/additionalCategoryActions';

const additionalCategoryAdapter = createEntityAdapter({
  selectId: (category) => category._id,
});
const AdditionalCategoryReducer = createSlice({
  name: 'additionalCategory',
  initialState: additionalCategoryAdapter.getInitialState({
    loading: true,
    error: null,
    allAdditionalCategoriesListCount: 0,
    filters: {
      limit: 50,
      sortBy: '',
      searchKey: '',
    },
  }),
  reducers: {
    setFilters: (state, action) => {
      const filterUpdates = action.payload;
      Object.keys(filterUpdates).forEach((filterKey) => {
        if (!_.isEqual(state.filters[filterKey], action.payload[filterKey])) {
          if (filterKey !== 'skip' && filterKey !== 'current') {
            filterUpdates.current = 1;
            filterUpdates.skip = 0;
          }
          state.filters[filterKey] = action.payload[filterKey];
        }
      });
    },
  },
  extraReducers: {
    [getAdditionalCategories.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getAdditionalCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.allAdditionalCategoriesListCount = action.payload.count;
      additionalCategoryAdapter.upsertMany(state, action.payload.categories);
      state.ids = action.payload.categories.map((category) => category._id);
    },
    [getAdditionalCategories.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [deleteAdditionalCategory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteAdditionalCategory.fulfilled]: (state, action) => {
      state.loading = false;
      additionalCategoryAdapter.removeOne(state, action.meta.arg.categoryId);
      state.allAdditionalCategoriesListCount = action.payload.count - 1;
    },
    [deleteAdditionalCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});
export const selectAdditionalCategoryLoading = (state) =>
  state.additionalCategory.loading;
export const selectAditionalCategoryCount = createSelector(
  [(state) => state.additionalCategory.allAdditionalCategoriesListCount],
  (count) => count
);
export const {
  selectAll: selectAllAdditionalCategories,
  selectById: selectAdditionalCategoryById,
} = additionalCategoryAdapter.getSelectors((state) => state.additionalCategory);
export const { setFilters } = AdditionalCategoryReducer.actions;
export default AdditionalCategoryReducer.reducer;

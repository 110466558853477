import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { message, Button } from 'antd';
import { updateOrderStatus } from 'redux/actions/orderActions';
import { StyledWrapper } from './Detail.styled';
import { statusStyle } from '../OrdersListing/listing.helper';
import { Colors } from 'constant';
const statuses = [
  { label: 'Pending', value: 'Pending', color: Colors.GREEN },
  { label: 'Prepare', value: 'Preparing', color: Colors.GREEN },
  { label: 'Complete', value: 'Completed', color: Colors.GREEN },
  { label: 'Collected', value: 'Collected', color: Colors.GREEN },
  { label: 'Reject', value: 'Rejected', color: Colors.RED, type: 'text' },
];

const OrderDetail = ({ orderDetails }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(orderDetails.status);
  const [isStatusLoading, setIsStatusLoading] = useState(false);

  const availableStatuses = useMemo(() => {
    switch (orderDetails.status) {
      case 'Pending':
        return [statuses[1], statuses[4]];
      case 'Preparing':
        return [statuses[2], statuses[4]];
      case 'Completed':
        return [statuses[3], statuses[4]];
      default:
        return [];
    }
  }, [orderDetails.status]);

  const saveStatus = async (value) => {
    let confirmValue;
    if (value === 'Rejected') {
      confirmValue = window.confirm(
        'Are you sure you want to reject the order?'
      );
    }
    if (confirmValue === false) {
      setStatus(orderDetails.status);
      return;
    }
    setStatus(value);

    if (orderDetails.status !== value && value !== undefined) {
      const today = new Date();

      const todayTime = moment(today, 'HH:mm:ss').format('hh:mm A');
      setIsStatusLoading(true);
      await dispatch(
        updateOrderStatus({
          _id: orderDetails._id,
          status: value,
          time: todayTime,
        })
      );
      setIsStatusLoading(false);
      message.success('Updated order status successfully');
    }
  };

  const StatusButton = (item) => {
    return (
      <Button
        type={item.type ? item.type : 'primary'}
        className="button"
        style={{
          background: item.type ? '' : item.color,
          color: item.type ? item.color : '',
        }}
        onClick={() => saveStatus(item.value)}
        loading={isStatusLoading}
      >
        {item.label}
      </Button>
    );
  };

  return (
    <StyledWrapper>
      <div className="layout-styled">
        <h2 className="NoMargin">
          Order Details: {orderDetails?._id?.substr(17, 24)}{' '}
          <span className="statusStyle">{statusStyle(status)}</span>
        </h2>
        <p className="lightBlack NoMargin">
          {moment(orderDetails?.date).format('MMMM DD, YYYY, hh:mm a')}
        </p>
        <p className="lightBlack NoMargin">
          Store Location: {orderDetails?.storeLocation?.name}
        </p>
        {orderDetails?.pickUpDateTime && (
          <p className="black">
            Pickup Date:{' '}
            {moment(orderDetails?.pickUpDateTime).format(
              'MMMM DD, YYYY, hh:mm a'
            )}
          </p>
        )}

        {!['Rejected', 'Cancelled', 'Collected'].includes(
          orderDetails.status
        ) && (
          <>
            <h3>
              Update Status:{' '}
              <span style={{ marginLeft: '10px' }}>
                {availableStatuses.map((text) => StatusButton(text))}
              </span>
            </h3>
            {orderDetails.status === 'Cancelled' && (
              <p style={{ color: 'red' }}>
                * Customer cancelled the order. You are not able to perform any
                status modification
              </p>
            )}
          </>
        )}
      </div>
    </StyledWrapper>
  );
};
OrderDetail.propTypes = {
  orderDetails: PropTypes.object,
};

export default OrderDetail;

import styled from 'styled-components';
import { Colors } from 'constant';

export const StyledWrapper = styled.div`
  .layout-styled {
    padding: 15px;
    background: ${Colors.WHITE};
    box-shadow: rgba(25, 25, 25, 0.04) 0 0 1px 0, rgba(0, 0, 0, 0.1) 0 3px 4px 0;
    border-radius: 12px;
    margin-bottom: 20px;
  }
  .link {
    color: ${Colors.LIGHT_BLACK};
    text-decoration: underline;
  }

  .greyColor {
    color: ${Colors.GEEK_BLUE};
  }
  .createdCustomer {
    margin-top: 1rem;
    margin-bottom: 0rem;
    font-weight: 500;
    font-size: 1rem;
  }
  .userIcon {
    color: ${Colors.GREEN};
    margin-right: 0.2rem;
  }
  .card-style {
    margin: 20px 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02),
      0 6px 20px 0 rgba(0, 0, 0, 0.05);
  }

  .statusStyle {
    font-weight: 600;
    margin-left: 5px;
  }

  .NoMargin {
    margin-bottom: 0px;
  }
  .pointCurrency {
    color: ${Colors.YELLOW};
  }
  .lessMargin {
    margin-bottom: 0.3rem;
  }
  .ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 20%;
    min-width: 20%;
    float: right;
  }
`;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderDetail } from 'redux/actions/orderActions';
import { selectOrderById } from 'redux/reducers/orderReducer';
import { StyledWrapper } from './Detail.styled';
import OrderDetail from './OrderDetail';
import CustomerDetail from './CustomerDetail';
import ReceiptData from './ReceiptData';
import GoBack from 'components/GoBack';

const Detail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { orderId } = location?.state || {};
  const orderDetails =
    useSelector((state) => selectOrderById(state, orderId)) || {};

  useEffect(() => {
    dispatch(getOrderDetail({ id: orderId }));
  }, [dispatch, orderId]);

  return (
    <StyledWrapper>
      <GoBack linkUrl={'/dashboard/orders'} />
      <OrderDetail orderDetails={orderDetails} />
      <CustomerDetail orderDetails={orderDetails} />
      <ReceiptData orderDetails={orderDetails} />
    </StyledWrapper>
  );
};
Detail.propTypes = {};

export default Detail;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { authAPIs } from 'services/api';
import axiosInstance from 'services/apiService';

export const login = createAsyncThunk('auth/login', async (payload) => {
  const { data } = await axiosInstance({ ...authAPIs.login, data: payload });
  return data;
});

export const loginWithGoogle = createAsyncThunk(
  'auth/google-login',
  async (payload, { getState }) => {
    const { data } = await axiosInstance({
      ...authAPIs.loginWithGoogle,
      data: payload,
    });
    return data;
  }
);

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { message, Card } from 'antd';
import { debounce } from 'lodash';
import { DownloadOutlined } from '@ant-design/icons';
import { AddButton, AddIcon } from 'modules/requests/requests.styles';
import { getSkipCount } from 'utils';
import {
  TableView,
  StyledExportButton,
} from '../../../components/StyledLayout/Layout.styled';
import SearchHeader from '../../../components/SearchHeader';
import {
  getSortType,
  categoryTableColumn,
  exportExcelFile,
} from './listing.helper';
import { deleteCategory, getCategories } from 'redux/actions/categoryActions';
import {
  selectAllCategories,
  selectCategoryCount,
  selectCategoryLoading,
  setFilters,
} from 'redux/reducers/categoryReducer';

const CategoryListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allCatgeories = useSelector(selectAllCategories);
  const isLoading = useSelector(selectCategoryLoading);
  const allCategoriesListCount = useSelector(selectCategoryCount);

  const initialPageConfig = {
    current: 1,
    pageSize: 10,
    limit: 10,
    skip: getSkipCount(1, 10),
    role: 'admin',
  };
  const [pageConfig, setPageConfig] = useState(initialPageConfig);

  const onChange = (pagination, filters, sorter) => {
    const newPageConfig = {
      current: pagination.current,
      pageSize: pagination.pageSize,
      limit: pagination.pageSize,
      skip: getSkipCount(pagination.current, pagination.pageSize),
      sortBy: getSortType(sorter),
      status: filters?.status ? filters.status[0] : '',
      userType: filters?.userType ? filters.userType[0] : '',
      searchKey: pageConfig?.searchKey,
      role: 'admin',
    };
    setPageConfig(newPageConfig);

    dispatch(setFilters(newPageConfig));
  };

  const onCategoryDelete = async (category) => {
    try {
      const body = {
        categoryId: category._id,
      };
      await dispatch(deleteCategory(body));
      message.success('Category deleted successfully');
    } catch (err) {
      message.error('Error while deleting category');
    }
  };

  useEffect(() => {
    dispatch(getCategories(pageConfig));
  }, [dispatch, pageConfig]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const categoryColumns = categoryTableColumn(onCategoryDelete);
  const handleSearch = debounce((e) => {
    setPageConfig({
      ...pageConfig,
      searchKey: e.target.value,
    });
  }, 500);
  const tableRef = useRef(null);

  return (
    <>
      <SearchHeader handleSearch={handleSearch} />

      <Card
        title="Categories"
        bordered={true}
        extra={
          <>
            <AddButton onClick={() => navigate('/dashboard/addCategory')}>
              <AddIcon style={{ marginRight: '4px' }} />
              New
            </AddButton>
            <StyledExportButton
              onClick={() => {
                exportExcelFile(allCatgeories, 'category');
              }}
            >
              <DownloadOutlined /> Export{' '}
            </StyledExportButton>
          </>
        }
      >
        <TableView
          ref={tableRef}
          columns={categoryColumns}
          dataSource={allCatgeories}
          pagination={{
            position: ['none', 'bottomRight'],
            total: allCategoriesListCount,
            showSizeChanger: false,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} Categories`,
            showQuickJumper: true,
            defaultPageSize: 50,
            ...pageConfig,
          }}
          scroll={{ x: 1000 }}
          onChange={onChange}
          loading={isLoading}
          bordered={true}
        />
      </Card>
    </>
  );
};

CategoryListing.propTypes = {};

export default CategoryListing;

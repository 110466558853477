import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from 'App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import './index.css';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { injectStore } from 'services/apiService';
import SocketProvider from '../src/providers/SocketProvider';

injectStore(store);

let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <SocketProvider>
      <PersistGate loading={null} persistor={persistor}>
        <React.StrictMode>
          <Suspense fallback="Loading...">
            <App />
          </Suspense>
        </React.StrictMode>
      </PersistGate>
    </SocketProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

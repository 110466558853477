import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper } from './Detail.styled';
import { TableView } from '../../../components/StyledLayout/Layout.styled';
import { EventTableColumn } from './detail.helper';
import RedeemedEventProducts from './RedeemedEventDiscount';

const RedeemedEventDiscount = ({ customer }) => {
  const [visible, setVisible] = useState(false);
  const eventDiscountsColumns = EventTableColumn(
    visible,
    setVisible,
    RedeemedEventProducts
  );

  return (
    <StyledWrapper>
      <div className="layout-styled">
        <h2>Redeemed Event Discount Products:</h2>
        <TableView
          columns={eventDiscountsColumns}
          dataSource={customer?.eventHistory}
          bordered={false}
          pagination={false}
        />
      </div>
    </StyledWrapper>
  );
};
RedeemedEventDiscount.propTypes = {
  customer: PropTypes.object,
};

export default RedeemedEventDiscount;

/* eslint-disable max-lines */
import React from 'react';

export const ItemsTableColumn = () => [
  {
    title: 'Products',
    render: (item) => (
      <div>
        <b>{item?.productId?.name?.en}</b>
        {item?.additionItems && item?.additionItems.length > 0 && (
          <p style={{ marginBottom: '0px' }}>
            {item?.additionItems.map(function (item) {
              return (
                <>
                  {item?.name?.en} ({item?.price})
                </>
              );
            })}
          </p>
        )}
        {item?.additionalInfo && (
          <p style={{ marginBottom: '0px' }}>{item?.additionalInfo}</p>
        )}
      </div>
    ),
    key: 'name',
    width: 80,
    align: 'left',
  },
  {
    title: 'Size',
    render: (item) => <>{item?.size}</>,
    key: 'size',
    width: 60,
    align: 'center',
  },
  {
    title: 'Quantity',
    render: (item) => <>{item?.quantity}</>,
    key: 'quantity',
    width: 60,
    align: 'center',
  },
  {
    title: 'Total amount',
    render: (item) => <>${item?.quantity * item?.price}</>,
    key: 'amount',
    width: 40,
    align: 'center',
  },
  {
    title: 'Discounted amount',
    render: (item) => <>${item?.discountTotal?.toFixed(2) || 0}</>,
    key: 'amount',
    width: 40,
    align: 'center',
  },
  {
    title: 'Discounted percentage',
    render: (item) => <>{item?.discountPercentage || 0}%</>,
    key: 'amount',
    width: 40,
    align: 'center',
  },
];

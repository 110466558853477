import styled from 'styled-components';
import { Input, Row } from 'antd';
import { Colors } from 'constant';

export const SearchField = styled(Input)`
  border-radius: 18px;
`;

export const StyledRow = styled(Row)`
  padding-bottom: 0.5rem;
  .anticon-search {
    color: lightgray;
  }
  .logutSection {
    text-align: right;
    margin: 10px 20px;
    cursor: pointer;
    :hover {
      text-decoration: underline;
      color: ${Colors.NAVBLUE};
      h3 {
        color: ${Colors.NAVBLUE};
      }
    }
  }
  .logoutIcon {
    margin-right: 4px;
  }
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { productAPIs } from 'services/api';
import axiosInstance from 'services/apiService';

export const addProduct = createAsyncThunk('product/add', async (payload) => {
  const { data } = await axiosInstance({
    ...productAPIs.add,
    data: payload,
  });
  return data;
});

export const deleteProduct = createAsyncThunk(
  'admin/deleteProduct',
  async ({ productId }, { getState }) => {
    const api = productAPIs.delete;
    const { data } = await axiosInstance({
      ...api,
      url: `product/${productId}/`,
    });
    return data.message;
  }
);

export const getProducts = createAsyncThunk(
  'admin/getProducts',
  async (payload, { getState }) => {
    const { data } = await axiosInstance({
      ...productAPIs.get,
      params: payload,
    });
    return data;
  }
);

export const updateProduct = createAsyncThunk(
  'product/update',
  async (payload) => {
    const api = productAPIs.update;

    const { data } = await axiosInstance({
      ...api,
      url: `product/${payload.id}/`,
      data: payload.formData,
    });
    return data;
  }
);

import styled, { css } from 'styled-components';
import { EN_FONT } from 'configs';
import { Colors } from 'constant';

const TYPES = {
  NORMAL: 'NORMAL',
  LIGHT: 'LIGHT',
  MEDIUM: 'MEDIUM',
  BOLD: 'BOLD',
};

const getTextWeight = (props) => {
  switch (props.type) {
    case TYPES.LIGHT:
      return 300;
    case TYPES.NORMAL:
      return 400;
    case TYPES.MEDIUM:
      return props.ar ? 500 : 600;
    case TYPES.BOLD:
      return props.ar ? 600 : 700;
    default:
      return 400;
  }
};

export const StyledText = styled.span`
  color: ${(props) => (props.color ? props.color : Colors.BLACK)};
  font-family: ${EN_FONT};
  font-size: ${(props) => (props.size ? props.size : 'inherit')};
  ${(props) =>
    css`
      font-weight: ${getTextWeight(props)};
    `}
`;

// Arabic
// Light 300
// Regular 400
// Medium 500
// Semi-bold 600

// English
// Light 300
// Regular 400
// Semi-bold 600
// Bold 700
